import React, { Fragment } from 'react'
import { RiMessageFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import PDFIcon from '../../../assets/pdf.png'
import { GoFile } from 'react-icons/go'

const SnapIntelExport = ({ convo, aiSummary, makeUrlsClickableAi }) => {
    const makeUrlsClickable = (text) => {
        if (!text) {
            return { __html: '' } // Return an empty string or handle the null case accordingly
        }

        // Regular expression to match URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g

        // Replace matched URLs with clickable <a> tags
        const replacedText = text.replace(
            urlRegex,
            (url) =>
                `<a class="hover:text-blue-600 hover:underline break-all font-bold" href="${url}" target="_blank"><br/> <br/> ${url} <br/> <br/></a>`
        )

        return { __html: replacedText }
    }
    return (
        <div className="h-full overflow-y-auto leading-3 pt-5 pb-10 ">
            <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-90% text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                <span>SNAPINTEL</span>
            </p>
            {convo?.snapintel && convo?.snapintel?.length > 0 ? (
                convo?.snapintel &&
                convo?.snapintel.map((convo, index) => (
                    <div className="w-full">
                        <div className="px-2">
                            {convo?.question?.question.length > 0 ? (
                                <p className="text-[.7em] transition-all duration-300 px-4 py-2 my-2 w-90% text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                                    {convo?.question?.question}
                                </p>
                            ) : null}

                            <div className="w-full flex flex-col justify-end items-end my-2">
                                <p className=" max-w-[80%] w-fit self-end text-[.7em] bg-white border px-4 py-2 shadow-sm rounded-md bg-opacity-70">
                                    <span className="flex gap-2 mb-2">
                                        <p className="text-main font-bold">
                                            {convo?.user?.fullname}
                                        </p>
                                    </span>
                                    <p
                                        className="whitespace-break-spaces "
                                        dangerouslySetInnerHTML={makeUrlsClickable(convo?.answer)}
                                    />

                                    {convo?.attachments && convo?.attachments.length > 0 && (
                                        <Fragment>
                                            {convo?.attachments.map((attachment) => (
                                                <div
                                                    className="flex items-start justify-start w-full"
                                                    key={attachment.id}
                                                >
                                                    {attachment.type === 'image' ? (
                                                        <div>
                                                            <img
                                                                alt={attachment.filename}
                                                                src={attachment.filename}
                                                                className="max-h-60"
                                                            />
                                                            <Link
                                                                to={attachment.filename}
                                                                key={attachment.id}
                                                                target="_blank"
                                                                className=" flex mt-2"
                                                            >
                                                                Image Link : &nbsp;
                                                                <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                    Click to view
                                                                </p>
                                                            </Link>
                                                        </div>
                                                    ) : attachment.type === 'file' ? (
                                                        <Link
                                                            to={attachment.filename}
                                                            target="__blank"
                                                            className="font-semibold flex flex-col items-end justify-center w-full mt-2"
                                                        >
                                                            PDF Link :{' '}
                                                            <span className="font-normal break-all text-blue-500 flex items-center hover:underline hover:font-semibold bg-gray-100 p-2 rounded-md">
                                                                <span className="flex items-center gap-2">
                                                                    <img
                                                                        alt="PDF Display"
                                                                        className="h-4 w-4"
                                                                        src={PDFIcon}
                                                                    />
                                                                    <p className="truncate">
                                                                        View PDF
                                                                    </p>
                                                                </span>
                                                                <span className="text-main ml-4">
                                                                    <GoFile
                                                                        style={{ color: '#7cc576' }}
                                                                    />
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    ) : null}
                                                </div>
                                            ))}
                                        </Fragment>
                                    )}
                                    <p className="mt-2 text-[.6rem] font-bold text-right text-darkgrey">
                                        Date: {convo?.created_at}
                                    </p>
                                </p>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="w-full h-full flex flex-col justify-center items-center ">
                    <p className="text-xl text-main">
                        <RiMessageFill color="#7cc576" />
                    </p>
                    <p className="text-lg text-gray-600">No Record</p>
                </div>
            )}
            <div className="w-full border-t border-main border-opacity-40 p-2 mb-8 pt-5">
                {/* <p className="text-main font-bold text-md w-full  justify-between flex"> */}
                {aiSummary && aiSummary.status === 'success' ? (
                    <>
                        <p className="text-main font-bold text-lg w-full  justify-between flex">
                            AI Summary of {aiSummary.event_name}
                        </p>
                        <div
                            className="text-sm text-justify px-2 pt-2"
                            dangerouslySetInnerHTML={makeUrlsClickableAi(aiSummary.summary)}
                        />
                    </>
                ) : (
                    <p>No summary available.</p>
                )}
                {/* </p> */}
            </div>
        </div>
    )
}

export default SnapIntelExport
