import { Companies } from 'components/AccordionDisplay/Companies'
import { Events } from 'components/AccordionDisplay/Events'
import { Questions } from 'components/AccordionDisplay/Questions'
import { Responses } from 'components/AccordionDisplay/Responses'
import { Users } from 'components/AccordionDisplay/Users'
import { checkboxesValueAtom } from 'components/CheckboxFilter'
import { useAtomValue } from 'jotai'
import React from 'react'

export const GlobalSearchAccordions = ({
    globalSearchResult,
    selectView,
    isLoading,
    selectedData,
}) => {
    const selectedFilters = useAtomValue(checkboxesValueAtom)

    return (
        <div className="flex flex-col gap-2">
            {selectedFilters?.events && (
                <Events
                    searchEvent={globalSearchResult?.events}
                    setTabs={() => selectView('event')}
                    setDataCon={(data) => selectedData(data)}
                    isLoading={isLoading}
                />
            )}
            {selectedFilters?.genSnapIntel && (
                <Companies
                    searchCompanies={globalSearchResult?.general_snapintel}
                    setTabs={() => selectView('company')}
                    setCompaniesContainer={(data) => selectedData(data)}
                    isLoading={isLoading}
                />
            )}
            {selectedFilters?.questions && (
                <Questions
                    searchQuestions={globalSearchResult?.questions}
                    setTabs={() => selectView('question')}
                    setQuestionsContainer={(data) => selectedData(data)}
                    isLoading={isLoading}
                />
            )}
            {selectedFilters?.response && (
                <Responses
                    searchResponses={globalSearchResult?.responses}
                    setTabs={() => selectView('response')}
                    setResponsesContainer={(data) => selectedData(data)}
                    isLoading={isLoading}
                />
            )}
            {selectedFilters?.user && (
                <Users
                    searchUsers={globalSearchResult?.users}
                    setTabs={() => selectView('user')}
                    setUsersContainer={(data) => selectedData(data)}
                    isLoading={isLoading}
                />
            )}
        </div>
    )
}
