import React, { Fragment, useEffect, useRef, useState } from 'react'
import { PDFExport } from '@progress/kendo-react-pdf'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FaWindowMinimize } from 'react-icons/fa'
import PDFIcon from '../../assets/pdf.png'
import { Link } from 'react-router-dom'
import { DownloadProgress } from 'components/DownloadingComponent'
import ImageView from 'components/ImageView'

export const UserMostExport = ({ data, onClose }) => {
    const pdfExportComponent = useRef(null)
    const [loading, setLoading] = useState()

    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save()
        }
    }
    const downloadPDF = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
        exportPDFWithComponent()
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose()
            }
        }

        window.addEventListener('keydown', handleKeyPress)

        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [onClose])

    const [selectedImage, setSelectedImage] = useState()
    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline font-bold break-all"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }
    useEffect(() => {
        if (data) {
            const initialStatus = data.reduce((acc, _, index) => {
                acc[index] = true
                return acc
            }, {})

            setChatDisplayStatus(initialStatus)
            setKiqsDisplayStatus(initialStatus)
            setSnapDisplayStatus(initialStatus)
        }
    }, [data])

    const [chatDisplayStatus, setChatDisplayStatus] = useState({})
    const [kiqsDisplayStatus, setKiqsDisplayStatus] = useState({})
    const [snapDisplayStatus, setSnapDisplayStatus] = useState({})

    const toggleDisplay = (type, userIndex) => {
        switch (type) {
            case 'chat':
                setChatDisplayStatus((prevStatus) => ({
                    ...prevStatus,
                    [userIndex]: !prevStatus[userIndex],
                }))
                break
            case 'kiqs':
                setKiqsDisplayStatus((prevStatus) => ({
                    ...prevStatus,
                    [userIndex]: !prevStatus[userIndex],
                }))
                break
            case 'snap':
                setSnapDisplayStatus((prevStatus) => ({
                    ...prevStatus,
                    [userIndex]: !prevStatus[userIndex],
                }))
                break
            default:
                break
        }
    }

    return (
        <div className=" top-0 bottom-0  right-0 w-full overflow-x-hidden h-screen bg-white z-50 pt-4 flex flex-col justify-start items-center">
            <div className="w-full flex flex-col items-center justify-between px-5 sticky -top-4 bg-white pt-4 shadow-md">
                {loading ? (
                    <DownloadProgress />
                ) : (
                    <div className="w-full flex items-center justify-between ">
                        <button
                            onClick={() => downloadPDF()}
                            className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                        >
                            Download PDF
                        </button>

                        <p
                            className="text-2xl opacity-70 hover:opacity-100 items-center text-main cursor-pointer"
                            onClick={onClose}
                        >
                            <AiOutlineCloseCircle />
                        </p>
                    </div>
                )}
                <p className="text-xs text-main pt-1 opacity-70 font-semibold">Preview</p>
            </div>
            <PDFExport
                fileName={`User With Most Response - ${data[0]?.companybelong?.firstname}`}
                ref={pdfExportComponent}
                paperSize="A4"
            >
                {selectedImage != null && (
                    <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
                )}

                <div className="w-full items-center mb-7 flex flex-col gap-2 p-2 ">
                    <div className="w-full h-fit flex items-center justify-center pt-4">
                        <p className="text-main font-semibold text-lg italic">
                            Users with Most Response
                        </p>
                    </div>

                    {data &&
                        data.map((user, userIndex) => (
                            <div className="min-w-[480px]" key={userIndex}>
                                <div
                                    className="w-full justify-between text-sm flex items-center shadow-md rounded-md py-2 px-2 bg-[#e7e7e7] cursor-pointer"
                                    onClick={() => {
                                        toggleDisplay('chat', userIndex)
                                        toggleDisplay('kiqs', userIndex)
                                        toggleDisplay('snap', userIndex)
                                    }}
                                >
                                    <p>
                                        <span className="text-xs font-semibold text-main">
                                            {user.companybelong.firstname}:
                                        </span>
                                        <span className="text-xs font-semibold">
                                            {user.fullname} |{' '}
                                        </span>
                                        <span className="text-xs font-semibold">
                                            {user?.responses_count}
                                        </span>
                                    </p>
                                    <span className="font-normal cursor-pointer mb-1 mr-1 hover:scale-110">
                                        <FaWindowMinimize style={{ color: '#000000' }} />
                                    </span>
                                </div>
                                <div className="w-full min-w-[330px] items-center justify-center">
                                    <>
                                        <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full min-w-[330px] text-white bg-main rounded-md shadow-md">
                                            <span className="flex">CHATROOM</span>
                                            <span
                                                onClick={() => toggleDisplay('chat', userIndex)}
                                                className="font-normal cursor-pointer hover:scale-110"
                                            >
                                                <FaWindowMinimize style={{ color: '#fff' }} />
                                            </span>
                                        </p>

                                        {chatDisplayStatus[userIndex] &&
                                            user?.chatroom_responses &&
                                            user?.chatroom_responses?.map((convo, convoIndex) => (
                                                <div
                                                    className="w-full text-xs rounded-md bg-gray-200 mb-1 shadow-sm px-5 py-3"
                                                    key={convoIndex}
                                                >
                                                    <p
                                                        className="text-sm text-justify break-words"
                                                        style={{ whiteSpace: 'pre-line' }}
                                                    >
                                                        {convo.answer &&
                                                            makeUrlsClickable(convo.answer).map(
                                                                (part, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {part}
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                    </p>
                                                    <div className="w-full">
                                                        {convo.attachments && (
                                                            <>
                                                                {convo.attachments.filter(
                                                                    (attach) =>
                                                                        attach.type === 'image'
                                                                ).length > 0 && (
                                                                    <div className="grid grid-cols-4 gap-2">
                                                                        {convo.attachments
                                                                            .filter(
                                                                                (attach) =>
                                                                                    attach.type ===
                                                                                    'image'
                                                                            )
                                                                            .map((attach) => (
                                                                                <div
                                                                                    key={attach.id}
                                                                                    className="overflow-hidden"
                                                                                >
                                                                                    <img
                                                                                        alt={
                                                                                            attach.filename
                                                                                        }
                                                                                        src={
                                                                                            attach.filename
                                                                                        }
                                                                                        className="object-cover w-full h-32"
                                                                                    />
                                                                                    <Link
                                                                                        to={
                                                                                            attach.filename
                                                                                        }
                                                                                        target="_blank"
                                                                                        className="flex mt-1"
                                                                                    >
                                                                                        <p className="font-normal mb-1 break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                            Click to
                                                                                            view
                                                                                        </p>
                                                                                    </Link>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                )}

                                                                {/* Display non-image attachments separately */}
                                                                {convo.attachments.filter(
                                                                    (attach) =>
                                                                        attach.type !== 'image'
                                                                ).length > 0 && (
                                                                    <div>
                                                                        {convo.attachments
                                                                            .filter(
                                                                                (attach) =>
                                                                                    attach.type !==
                                                                                    'image'
                                                                            )
                                                                            .map((attach) => (
                                                                                <div
                                                                                    className="h-28 w-full py-5 flex break-all text-xs"
                                                                                    key={attach.id}
                                                                                >
                                                                                    <img
                                                                                        alt="PDF Icon"
                                                                                        className="h-full w-fit"
                                                                                        src={
                                                                                            PDFIcon
                                                                                        }
                                                                                    />
                                                                                    <div className="w-full">
                                                                                        <p className="font-bold text-darkgrey">
                                                                                            Filename:
                                                                                        </p>
                                                                                        <Link
                                                                                            className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                            to={
                                                                                                attach.filename
                                                                                            }
                                                                                            target="__blank"
                                                                                        >
                                                                                            {
                                                                                                attach.filename
                                                                                            }
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                    </>
                                    <>
                                        <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full min-w-[330px] text-white bg-main rounded-md shadow-md">
                                            <span className="flex">MY KIQS</span>
                                            <span
                                                onClick={() => toggleDisplay('kiqs', userIndex)}
                                                className="font-normal cursor-pointer hover:scale-110"
                                            >
                                                <FaWindowMinimize style={{ color: '#fff' }} />
                                            </span>
                                        </p>

                                        {kiqsDisplayStatus[userIndex] &&
                                            user?.mykiqs_responses &&
                                            user?.mykiqs_responses?.map((convo, convoIndex) => (
                                                <div
                                                    className="w-full text-xs rounded-md bg-gray-200 mb-1 shadow-sm px-5 py-3"
                                                    key={convoIndex}
                                                >
                                                    <p
                                                        className="text-sm text-justify break-words"
                                                        style={{ whiteSpace: 'pre-line' }}
                                                    >
                                                        {convo.answer &&
                                                            makeUrlsClickable(convo.answer).map(
                                                                (part, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {part}
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                    </p>
                                                    <div className="w-full">
                                                        {convo.attachments && (
                                                            <>
                                                                {convo.attachments.filter(
                                                                    (attach) =>
                                                                        attach.type === 'image'
                                                                ).length > 0 && (
                                                                    <div className="grid grid-cols-4 gap-2">
                                                                        {convo.attachments
                                                                            .filter(
                                                                                (attach) =>
                                                                                    attach.type ===
                                                                                    'image'
                                                                            )
                                                                            .map((attach) => (
                                                                                <div
                                                                                    key={attach.id}
                                                                                    className="overflow-hidden"
                                                                                >
                                                                                    <img
                                                                                        alt={
                                                                                            attach.filename
                                                                                        }
                                                                                        src={
                                                                                            attach.filename
                                                                                        }
                                                                                        className="object-cover w-full h-32"
                                                                                    />
                                                                                    <Link
                                                                                        to={
                                                                                            attach.filename
                                                                                        }
                                                                                        target="_blank"
                                                                                        className="flex mt-1"
                                                                                    >
                                                                                        <p className="font-normal mb-1 break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                            Click to
                                                                                            view
                                                                                        </p>
                                                                                    </Link>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                )}

                                                                {convo.attachments.filter(
                                                                    (attach) =>
                                                                        attach.type !== 'image'
                                                                ).length > 0 && (
                                                                    <div>
                                                                        {convo.attachments
                                                                            .filter(
                                                                                (attach) =>
                                                                                    attach.type !==
                                                                                    'image'
                                                                            )
                                                                            .map((attach) => (
                                                                                <div
                                                                                    className="h-28 w-full py-5 flex break-all text-xs"
                                                                                    key={attach.id}
                                                                                >
                                                                                    <img
                                                                                        alt="PDF Icon"
                                                                                        className="h-full w-fit"
                                                                                        src={
                                                                                            PDFIcon
                                                                                        }
                                                                                    />
                                                                                    <div className="w-full">
                                                                                        <p className="font-bold text-darkgrey">
                                                                                            Filename:
                                                                                        </p>
                                                                                        <Link
                                                                                            className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                            to={
                                                                                                attach.filename
                                                                                            }
                                                                                            target="__blank"
                                                                                        >
                                                                                            {
                                                                                                attach.filename
                                                                                            }
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                    </>
                                    <>
                                        <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full min-w-[330px] text-white bg-main rounded-md shadow-md">
                                            <span className="flex">SNAPINTEL</span>
                                            <span
                                                onClick={() => toggleDisplay('snap', userIndex)}
                                                className="font-normal cursor-pointer hover:scale-110"
                                            >
                                                <FaWindowMinimize style={{ color: '#fff' }} />
                                            </span>
                                        </p>

                                        {snapDisplayStatus[userIndex] &&
                                            user?.snapintel_responses &&
                                            user?.snapintel_responses?.map((convo, convoIndex) => (
                                                <div
                                                    className="w-full text-xs rounded-md bg-gray-200 mb-1 shadow-sm px-5 py-3"
                                                    key={convoIndex}
                                                >
                                                    <p
                                                        className="text-sm text-justify break-words"
                                                        style={{ whiteSpace: 'pre-line' }}
                                                    >
                                                        {convo.answer &&
                                                            makeUrlsClickable(convo.answer).map(
                                                                (part, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {part}
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                    </p>
                                                    <div className="w-full">
                                                        {convo.attachments && (
                                                            <>
                                                                {convo.attachments.filter(
                                                                    (attach) =>
                                                                        attach.type === 'image'
                                                                ).length > 0 && (
                                                                    <div className="grid grid-cols-4 gap-2">
                                                                        {convo.attachments
                                                                            .filter(
                                                                                (attach) =>
                                                                                    attach.type ===
                                                                                    'image'
                                                                            )
                                                                            .map((attach) => (
                                                                                <div
                                                                                    key={attach.id}
                                                                                    className="overflow-hidden"
                                                                                >
                                                                                    <img
                                                                                        alt={
                                                                                            attach.filename
                                                                                        }
                                                                                        src={
                                                                                            attach.filename
                                                                                        }
                                                                                        className="object-cover w-full h-32"
                                                                                    />
                                                                                    <Link
                                                                                        to={
                                                                                            attach.filename
                                                                                        }
                                                                                        target="_blank"
                                                                                        className="flex mt-1"
                                                                                    >
                                                                                        <p className="font-normal mb-1 break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                            Click to
                                                                                            view
                                                                                        </p>
                                                                                    </Link>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                )}

                                                                {convo.attachments.filter(
                                                                    (attach) =>
                                                                        attach.type !== 'image'
                                                                ).length > 0 && (
                                                                    <div>
                                                                        {convo.attachments
                                                                            .filter(
                                                                                (attach) =>
                                                                                    attach.type !==
                                                                                    'image'
                                                                            )
                                                                            .map((attach) => (
                                                                                <div
                                                                                    className="h-28 w-full py-5 flex break-all text-xs"
                                                                                    key={attach.id}
                                                                                >
                                                                                    <img
                                                                                        alt="PDF Icon"
                                                                                        className="h-full w-fit"
                                                                                        src={
                                                                                            PDFIcon
                                                                                        }
                                                                                    />
                                                                                    <div className="w-full">
                                                                                        <p className="font-bold text-darkgrey">
                                                                                            Filename:
                                                                                        </p>
                                                                                        <Link
                                                                                            className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                            to={
                                                                                                attach.filename
                                                                                            }
                                                                                            target="__blank"
                                                                                        >
                                                                                            {
                                                                                                attach.filename
                                                                                            }
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                    </>
                                </div>
                            </div>
                        ))}
                </div>
            </PDFExport>
        </div>
    )
}

export default UserMostExport
