import React, { useEffect, useRef, useState } from 'react'
import NexusLogo from '../../assets/NexusBrain.png'
import { PDFExport } from '@progress/kendo-react-pdf'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { FaFile } from 'react-icons/fa'
import PDFIcon from '../../assets/pdf.png'
import { DownloadProgress } from 'components/DownloadingComponent'
import axios from 'axios'

const CompaniesExport = ({ dataCon, onClose }) => {
    const data = dataCon?.[0]
    const pdfExportComponent = useRef(null)
    const [loading, setLoading] = useState()
    const [summarizing, setSummarizing] = useState(false)
    const exportPDF = () => {
        setLoading(true) // Start loading

        if (pdfExportComponent.current) {
            pdfExportComponent.current.save((pdfBlob) => {
                if (pdfBlob instanceof Blob) {
                    const fileObject = blobToFile(
                        pdfBlob,
                        `Export Spaces and Events - ${data?.event_name}.pdf`
                    )
                    console.log('File Object:', fileObject) // Log the file object
                    sendPDFToBackend(fileObject) // No need for finally here
                } else {
                    console.error('The exported PDF is not a Blob:', pdfBlob)
                    setLoading(false) // Stop loading in case of error
                }
            })
        } else {
            setLoading(false) // Stop loading if pdfExportComponent is not available
        }
    }

    const blobToFile = (blob, filename) => {
        return new File([blob], filename, { type: 'application/pdf' })
    }

    const [uploadMessage, setUploadMessage] = useState('')
    const [aiSummary, setAiSummary] = useState()

    const sendPDFToBackend = (fileObject) => {
        setSummarizing(true) // Start summarizing

        axios
            .post(
                'https://test-v1.nexusbrain-core-ai-api.nbtechbeta.com/api/summarize/summarize_text',
                data
            )
            .then((response) => {
                console.log('PDF successfully sent to the server:', response)
                setUploadMessage('PDF uploaded successfully!')
                setAiSummary(response.data)
            })
            .catch((error) => {
                console.error('Error uploading the PDF:', error)
                setUploadMessage('Error uploading PDF. Please try again.')
            })
            .finally(() => {
                setSummarizing(false) // Reset summarizing state
            })
    }
    const makeUrlsClickableAi = (text) => {
        // Replace URLs with anchor tags
        const urlRegex =
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
        text = text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>')

        // Replace newlines with <br />
        text = text.replace(/\n/g, '<br />')

        return { __html: text }
    }
    const makeUrlsClickable = (text) => {
        if (!text) {
            return { __html: '' }
        }

        const urlRegex = /(https?:\/\/[^\s]+)/g

        const replacedText = text.replace(
            urlRegex,
            (url) =>
                `<a class="hover:text-blue-600 hover:underline font-bold break-all whitespace-normal break-words  max-w-[100px]" href="${url}" target="_blank"><br/> <br/> ${url} <br/> <br/></a>`
        )

        return { __html: replacedText }
    }

    const pdfref = useRef(null)

    const handleClickOutside = (event) => {
        if (pdfref.current && !pdfref.current.contains(event.target)) {
            onClose(null) // Close the popup
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div
            ref={pdfref}
            className=" top-0 right-0 w-full overflow-x-hidden h-screen bg-white z-10 pt-4 flex flex-col justify-start items-center"
        >
            <div className="w-full flex flex-col items-center justify-between px-5 sticky -top-4 bg-white pt-4 shadow-md">
                {loading || summarizing ? (
                    // <DownloadProgress />
                    <DownloadProgress progress={loading} isSummarizing={summarizing} />
                ) : (
                    <div className="w-full flex items-center justify-between gap-4">
                        {/* {uploadMessage && <div className="notification">{uploadMessage}</div>} */}
                        <div className="flex gap-2">
                            <button
                                onClick={exportPDF}
                                className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                            >
                                Download PDF
                            </button>

                            <button
                                onClick={sendPDFToBackend}
                                className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                                disabled={summarizing} // Disable button while summarizing
                            >
                                {summarizing ? 'Summarizing...' : 'AI Summarize'}
                            </button>
                        </div>
                        <p
                            className="text-2xl opacity-70 hover:opacity-100 items-center text-main cursor-pointer"
                            onClick={onClose}
                        >
                            <AiOutlineCloseCircle />
                        </p>
                    </div>
                )}
                <p className="text-xs text-main pt-1 opacity-70 font-semibold">Preview</p>
            </div>
            <PDFExport
                fileName={`Export Company - ${data?.event_name}`}
                ref={pdfExportComponent}
                paperSize="A4"
            >
                <div className="w-full flex flex-col gap-4 p-4 ">
                    <div className=" pt-2 px-5 text-main flex gap-5 items-center">
                        <div>
                            <img src={NexusLogo} alt="NexusLogo" className="w-10" />
                        </div>
                        <div>
                            <p className="text-md h-fit font-bold">{data?.event_name}</p>
                        </div>
                    </div>
                    <p className="w-[90%] text-xs"> {data?.description}</p>
                    <div className="w-full  items-end justify-end pb-3 border-main border-opacity-30 text-xs">
                        <p className="font-bold">Agents:</p>
                        <div className="flex w-full flex-col mb-2">
                            {data?.groups?.map((group, groupIndex) => {
                                return (
                                    <div>
                                        <p className="font-semibold text-main space-x-9   whitespace-nowrap">
                                            Group: {group?.group_name}
                                        </p>
                                        <div className="flex-col w-fit">
                                            {group.users &&
                                                group.users.map((user, userIndex) => {
                                                    return (
                                                        <p>
                                                            {' '}
                                                            &nbsp; {user?.fullname}
                                                            {group.users.length - 1 > userIndex &&
                                                                ''}{' '}
                                                        </p>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="w-full border-t border-main border-opacity-40 pt-2  break-words whitespace-break-spaces">
                            <p className="text-main font-bold text-sm w-full  justify-between flex">
                                General Discussionss
                            </p>
                            <div className="w-full flex flex-col px-5 py-2 text-[.7rem] leading-4 gap-1">
                                {data?.general_discussions &&
                                    data?.general_discussions?.map((item) => (
                                        <>
                                            <div
                                                key={item}
                                                className=" w-full border rounded-md px-2 py-1 flex flex-col"
                                            >
                                                {item?.question?.question && (
                                                    <p className="text-main font-bold">
                                                        Question: {item?.question?.question}
                                                    </p>
                                                )}
                                                <p className="text-main font-bold">
                                                    {item.user.fullname} -{' '}
                                                    <span className="font-semibold text-darkgrey">
                                                        {item.created_at}
                                                    </span>
                                                </p>

                                                <p
                                                    dangerouslySetInnerHTML={makeUrlsClickable(
                                                        item.answer
                                                    )}
                                                />
                                                {item.attachments &&
                                                    item.attachments.length > 0 && (
                                                        <div>
                                                            {item.attachments.map(
                                                                (attachment, attachmentIndex) => (
                                                                    <div
                                                                        className="flex items-start justify-start w-fit "
                                                                        key={attachment.id}
                                                                    >
                                                                        {attachment.type ===
                                                                        'image' ? (
                                                                            <div>
                                                                                <img
                                                                                    alt={
                                                                                        attachment.filename
                                                                                    }
                                                                                    src={
                                                                                        attachment.filename
                                                                                    }
                                                                                    className="max-h-60"
                                                                                />
                                                                                <Link
                                                                                    to={
                                                                                        attachment.filename
                                                                                    }
                                                                                    key={
                                                                                        attachment.id
                                                                                    }
                                                                                    target="_blank"
                                                                                    className="font-bold mt-2 flex"
                                                                                >
                                                                                    Image Link :{' '}
                                                                                    <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                        Click to
                                                                                        view
                                                                                    </p>
                                                                                </Link>
                                                                            </div>
                                                                        ) : attachment.type ===
                                                                          'file' ? (
                                                                            <Link
                                                                                to={
                                                                                    attachment.filename
                                                                                }
                                                                                target="_blank"
                                                                                className="font-bold mb-4"
                                                                            >
                                                                                PDF Link :{' '}
                                                                                <span className="font-normal break-all text-blue-500 flex items-center hover:underline hover:font-semibold">
                                                                                    <span>
                                                                                        <img
                                                                                            alt="PDF Display"
                                                                                            className="h-8"
                                                                                            src={
                                                                                                PDFIcon
                                                                                            }
                                                                                        />
                                                                                    </span>
                                                                                    Go to link
                                                                                </span>
                                                                            </Link>
                                                                        ) : null}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                            </div>

                                            <div className="w-full">
                                                {item && item.responses?.length > 0 && (
                                                    <div>
                                                        <p> Replies : </p>
                                                        <div className="flex flex-col w-full ml-5 px-6 gap-1">
                                                            {item.responses.map((responses) => (
                                                                <div className="border rounded-md pt-1 pl-2">
                                                                    <span className=" font-bold">
                                                                        {responses?.user?.fullname}
                                                                    </span>
                                                                    <div
                                                                        className=" flex flex-col w-fit bg-opacity-30  my-1 rounded-l-md rounded-tr-md shadow-sm"
                                                                        key={responses.id}
                                                                    >
                                                                        <p
                                                                            className="break-words"
                                                                            dangerouslySetInnerHTML={makeUrlsClickable(
                                                                                responses.answer
                                                                            )}
                                                                        />

                                                                        {responses.attachments &&
                                                                            responses.attachments
                                                                                .length > 0 && (
                                                                                <div>
                                                                                    {responses.attachments.map(
                                                                                        (
                                                                                            attachment,
                                                                                            attachmentIndex
                                                                                        ) => (
                                                                                            <div
                                                                                                className="flex items-start justify-start w-fit "
                                                                                                key={
                                                                                                    attachment.id
                                                                                                }
                                                                                            >
                                                                                                {attachment.type ===
                                                                                                'image' ? (
                                                                                                    <div>
                                                                                                        <img
                                                                                                            alt={
                                                                                                                attachment.filename
                                                                                                            }
                                                                                                            src={
                                                                                                                attachment.filename
                                                                                                            }
                                                                                                            className="max-h-60"
                                                                                                        />
                                                                                                        <Link
                                                                                                            to={
                                                                                                                attachment.filename
                                                                                                            }
                                                                                                            key={
                                                                                                                attachment.id
                                                                                                            }
                                                                                                            target="_blank"
                                                                                                            className="font-bold mt-2 flex"
                                                                                                        >
                                                                                                            Image
                                                                                                            Link
                                                                                                            :{' '}
                                                                                                            <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                                Click
                                                                                                                to
                                                                                                                view
                                                                                                            </p>
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                ) : attachment.type ===
                                                                                                  'file' ? (
                                                                                                    <Link
                                                                                                        to={
                                                                                                            attachment.filename
                                                                                                        }
                                                                                                        target="_blank"
                                                                                                        className="font-bold "
                                                                                                    >
                                                                                                        PDF
                                                                                                        Link
                                                                                                        :{' '}
                                                                                                        <span className="font-normal break-all text-blue-500 flex hover:underline hover:font-semibold">
                                                                                                            <span>
                                                                                                                <FaFile />
                                                                                                            </span>
                                                                                                            Go
                                                                                                            to
                                                                                                            link
                                                                                                        </span>
                                                                                                    </Link>
                                                                                                ) : null}
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        <span className="">
                                                                            <span>Date: </span>{' '}
                                                                            {responses.created_at}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className=" border-b-2 border-main border-opacity-50 h-1 my-1" />
                                        </>
                                    ))}
                            </div>
                        </div>

                        <div className="w-full border-t border-main border-opacity-40 pt-5  break-words whitespace-break-spaces">
                            <p className="text-main font-bold text-md w-full  justify-between flex ">
                                Chatroom KIQs
                            </p>
                            <div className="w-full flex flex-col px-2 py-5 text-[.7rem] leading-4 gap-1">
                                {data?.chatroom &&
                                    data?.chatroom?.map((item) => (
                                        <>
                                            <div
                                                key={item}
                                                className=" w-full border rounded-md p-2 flex flex-col"
                                            >
                                                {item?.question?.question && (
                                                    <p className="text-main font-bold">
                                                        Question: {item?.question?.question}
                                                    </p>
                                                )}
                                                <p className="text-main font-bold">
                                                    {item.user.fullname} -{' '}
                                                    <span className="font-semibold text-darkgrey">
                                                        {item.created_at}
                                                    </span>
                                                </p>
                                                <p
                                                    dangerouslySetInnerHTML={makeUrlsClickable(
                                                        item.answer
                                                    )}
                                                />
                                                {item.attachments &&
                                                    item.attachments.length > 0 && (
                                                        <div>
                                                            {item.attachments.map((attachment) => (
                                                                <div
                                                                    className="flex items-start justify-start w-fit "
                                                                    key={attachment.id}
                                                                >
                                                                    {attachment.type === 'image' ? (
                                                                        <div>
                                                                            <img
                                                                                alt={
                                                                                    attachment.filename
                                                                                }
                                                                                src={
                                                                                    attachment.filename
                                                                                }
                                                                                className="max-h-60"
                                                                            />
                                                                            <Link
                                                                                to={
                                                                                    attachment.filename
                                                                                }
                                                                                target="_blank"
                                                                                key={attachment.id}
                                                                                className="font-bold flex"
                                                                            >
                                                                                Image Link :{' '}
                                                                                <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                    Click to view
                                                                                </p>
                                                                            </Link>
                                                                        </div>
                                                                    ) : attachment.type ===
                                                                      'file' ? (
                                                                        <Link
                                                                            to={attachment.filename}
                                                                            target="_blank"
                                                                            className="font-bold mb-4"
                                                                        >
                                                                            PDF Link :{' '}
                                                                            <span className="font-normal break-all text-blue-500 flex items-center hover:underline hover:font-semibold">
                                                                                <span>
                                                                                    <img
                                                                                        alt="PDF Display"
                                                                                        className="h-8"
                                                                                        src={
                                                                                            PDFIcon
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                                Go to link
                                                                            </span>
                                                                        </Link>
                                                                    ) : null}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                            </div>
                                            <div>
                                                {item && item.responses?.length > 0 && (
                                                    <div>
                                                        <p> Replies : </p>
                                                        <div className="flex flex-col w-full ml-5 px-6 gap-2">
                                                            {item.responses.map((responses) => (
                                                                <div className="border rounded-md pt-2 pl-2">
                                                                    <div className="">
                                                                        <span className=" font-bold">
                                                                            {
                                                                                responses?.user
                                                                                    ?.fullname
                                                                            }
                                                                            -
                                                                        </span>

                                                                        <span>
                                                                            {' '}
                                                                            {responses.created_at}
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        className=" flex flex-col w-fit bg-opacity-30 pb-1 rounded-l-md rounded-tr-md shadow-sm leading-4 gap-1"
                                                                        key={responses.id}
                                                                    >
                                                                        <p
                                                                            dangerouslySetInnerHTML={makeUrlsClickable(
                                                                                responses.answer
                                                                            )}
                                                                        />

                                                                        {responses.attachments &&
                                                                            responses.attachments
                                                                                .length > 0 && (
                                                                                <div>
                                                                                    {responses.attachments.map(
                                                                                        (
                                                                                            attachment,
                                                                                            attachmentIndex
                                                                                        ) => (
                                                                                            <div
                                                                                                className="flex items-start justify-start w-fit "
                                                                                                key={
                                                                                                    attachment.id
                                                                                                }
                                                                                            >
                                                                                                {attachment.type ===
                                                                                                'image' ? (
                                                                                                    <div>
                                                                                                        <img
                                                                                                            alt={
                                                                                                                attachment.filename
                                                                                                            }
                                                                                                            src={
                                                                                                                attachment.filename
                                                                                                            }
                                                                                                            className="max-h-60"
                                                                                                        />
                                                                                                        <Link
                                                                                                            to={
                                                                                                                attachment.filename
                                                                                                            }
                                                                                                            target="_blank"
                                                                                                            key={
                                                                                                                attachment.id
                                                                                                            }
                                                                                                            className="font-bold flex"
                                                                                                        >
                                                                                                            Image
                                                                                                            Link
                                                                                                            :{' '}
                                                                                                            <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                                Click
                                                                                                                to
                                                                                                                view
                                                                                                            </p>
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                ) : attachment.type ===
                                                                                                  'file' ? (
                                                                                                    <Link
                                                                                                        to={
                                                                                                            attachment.filename
                                                                                                        }
                                                                                                        target="_blank"
                                                                                                        className="font-bold "
                                                                                                    >
                                                                                                        PDF
                                                                                                        Link
                                                                                                        :{' '}
                                                                                                        <span className="font-normal break-all text-blue-500 flex hover:underline hover:font-semibold">
                                                                                                            <span>
                                                                                                                <FaFile />
                                                                                                            </span>
                                                                                                            Go
                                                                                                            to
                                                                                                            link
                                                                                                        </span>
                                                                                                    </Link>
                                                                                                ) : null}
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className=" border-b-2 border-main border-opacity-50 h-1 my-1" />
                                        </>
                                    ))}
                            </div>
                        </div>

                        <div className="w-full border-t border-main border-opacity-40 pt-5  break-words whitespace-break-spaces">
                            <p className="text-main font-bold text-md w-full  justify-between flex">
                                MY KIQs
                            </p>
                            <div className="w-full flex flex-col px-2 py-5 text-[.7rem] leading-4 gap-1">
                                {data?.mykiqs &&
                                    data?.mykiqs?.map((item) => (
                                        <>
                                            <div
                                                key={item}
                                                className=" w-full border rounded-md p-2 flex flex-col "
                                            >
                                                {item?.question?.question && (
                                                    <p className="text-main font-bold">
                                                        Question: {item?.question?.question}
                                                    </p>
                                                )}
                                                <p className="text-main font-bold">
                                                    {item.user.fullname} -{' '}
                                                    <span className="font-semibold text-darkgrey">
                                                        {item.created_at}
                                                    </span>
                                                </p>

                                                <p
                                                    dangerouslySetInnerHTML={makeUrlsClickable(
                                                        item.answer
                                                    )}
                                                />
                                                {item.attachments &&
                                                    item.attachments.length > 0 && (
                                                        <div>
                                                            {item.attachments.map((attachment) => (
                                                                <div
                                                                    className="flex items-start justify-start w-fit "
                                                                    key={attachment.id}
                                                                >
                                                                    {attachment.type === 'image' ? (
                                                                        <div>
                                                                            <img
                                                                                alt={
                                                                                    attachment.filename
                                                                                }
                                                                                src={
                                                                                    attachment.filename
                                                                                }
                                                                                className="max-h-60"
                                                                            />
                                                                            <Link
                                                                                to={
                                                                                    attachment.filename
                                                                                }
                                                                                target="_blank"
                                                                                key={attachment.id}
                                                                                className="font-bold flex"
                                                                            >
                                                                                Image Link :{' '}
                                                                                <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                    Click to view
                                                                                </p>
                                                                            </Link>
                                                                        </div>
                                                                    ) : attachment.type ===
                                                                      'file' ? (
                                                                        <Link
                                                                            to={attachment.filename}
                                                                            target="_blank"
                                                                            className="font-bold mb-4"
                                                                        >
                                                                            PDF Link :{' '}
                                                                            <span className="font-normal break-all text-blue-500 flex items-center hover:underline hover:font-semibold">
                                                                                <span>
                                                                                    <img
                                                                                        alt="PDF Display"
                                                                                        className="h-8"
                                                                                        src={
                                                                                            PDFIcon
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                                Go to link
                                                                            </span>
                                                                        </Link>
                                                                    ) : null}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                            </div>

                                            <div className=" border-b-2 border-main border-opacity-50 h-1 my-1" />
                                        </>
                                    ))}
                            </div>
                        </div>

                        <div className="w-full border-t border-main border-opacity-40 pt-5  break-words whitespace-break-spaces">
                            <p className="text-main font-bold text-md w-full  justify-between flex">
                                SnapIntel
                            </p>
                            <div className="w-full flex flex-col px-2 py-2 text-[.7rem] leading-4 gap-1 ">
                                {data?.snap_intel &&
                                    data?.snap_intel.map((item) => (
                                        <>
                                            <div
                                                key={item}
                                                className=" w-full  border rounded-md px-2 py-1 flex flex-col"
                                            >
                                                {item?.question?.question && (
                                                    <p className="text-main font-bold ">
                                                        Question: {item?.question?.question}
                                                    </p>
                                                )}
                                                <p className="text-main font-bold">
                                                    {item.user.fullname} -{' '}
                                                    <span className="font-semibold text-darkgrey">
                                                        {item.created_at}
                                                    </span>
                                                </p>

                                                <div className="w-full  h-fit ">
                                                    <p
                                                        className="w-fit "
                                                        dangerouslySetInnerHTML={makeUrlsClickable(
                                                            item.answer
                                                        )}
                                                    />
                                                </div>

                                                {/* <p>{item.answer}</p> */}

                                                {item.attachments &&
                                                    item.attachments.length > 0 && (
                                                        <div>
                                                            {item.attachments.map(
                                                                (attachment, attachmentIndex) => (
                                                                    <div
                                                                        className="flex items-start justify-start w-fit "
                                                                        key={attachment.id}
                                                                    >
                                                                        {attachment.type ===
                                                                        'image' ? (
                                                                            <div>
                                                                                <img
                                                                                    alt={
                                                                                        attachment.filename
                                                                                    }
                                                                                    src={
                                                                                        attachment.filename
                                                                                    }
                                                                                    className="max-h-60"
                                                                                />
                                                                                <Link
                                                                                    to={
                                                                                        attachment.filename
                                                                                    }
                                                                                    target="_blank"
                                                                                    key={
                                                                                        attachment.id
                                                                                    }
                                                                                    className="font-bold flex"
                                                                                >
                                                                                    Image Link :{' '}
                                                                                    <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                        Click to
                                                                                        view
                                                                                    </p>
                                                                                </Link>
                                                                            </div>
                                                                        ) : attachment.type ===
                                                                          'file' ? (
                                                                            <Link
                                                                                to={
                                                                                    attachment.filename
                                                                                }
                                                                                target="_blank"
                                                                                className="font-bold mb-4"
                                                                            >
                                                                                PDF Link :{' '}
                                                                                <span className="font-normal break-all text-blue-500 flex items-center hover:underline hover:font-semibold">
                                                                                    <span>
                                                                                        <img
                                                                                            alt="PDF Display"
                                                                                            className="h-8"
                                                                                            src={
                                                                                                PDFIcon
                                                                                            }
                                                                                        />
                                                                                    </span>
                                                                                    Go to link
                                                                                </span>
                                                                            </Link>
                                                                        ) : null}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                            </div>
                                        </>
                                    ))}
                                {/* <div className=" border-b-2 border-main border-opacity-50 h-1 my-1" /> */}
                            </div>
                        </div>
                    </div>
                    <div className="w-full border-t border-main border-opacity-40 p-2 mb-8 pt-5">
                        {/* <p className="text-main font-bold text-md w-full  justify-between flex"> */}
                        {aiSummary && aiSummary.status === 'success' ? (
                            <>
                                <p className="text-main font-bold text-lg w-full  justify-between flex">
                                    AI Summary of {aiSummary.event_name}
                                </p>
                                <div
                                    className="text-sm text-justify px-2 pt-2"
                                    dangerouslySetInnerHTML={makeUrlsClickableAi(aiSummary.summary)}
                                />
                            </>
                        ) : (
                            <p>No summary available.</p>
                        )}{' '}
                        {/* </p> */}
                    </div>
                </div>
            </PDFExport>
        </div>
    )
}

export default CompaniesExport
