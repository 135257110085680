import React, { Fragment, useRef, useState } from 'react'
import { RiMessageFill } from 'react-icons/ri'
import NexusLogo from '../../assets/NexusBrain.png'
import { Divider } from '@mui/material'
import { PDFExport } from '@progress/kendo-react-pdf'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import PDFIcon from '../../assets/pdf.png'
import { DownloadProgress } from 'components/DownloadingComponent'
import axios from 'axios'

const GeneralSnapIntelExport = ({ user, company, data: convoContainer, onClose }) => {
    const pdfExportComponent = useRef(null)
    const [loading, setLoading] = useState()
    const [summarizing, setSummarizing] = useState(false)
    const exportPDF = () => {
        setLoading(true) // Start loading

        if (pdfExportComponent.current) {
            pdfExportComponent.current.save((pdfBlob) => {
                if (pdfBlob instanceof Blob) {
                    const fileObject = blobToFile(
                        pdfBlob,
                        `Export Spaces and Events - ${company?.firstname}.pdf`
                    )
                    console.log('File Object:', fileObject) // Log the file object
                    sendPDFToBackend(fileObject) // No need for finally here
                } else {
                    console.error('The exported PDF is not a Blob:', pdfBlob)
                    setLoading(false) // Stop loading in case of error
                }
            })
        } else {
            setLoading(false) // Stop loading if pdfExportComponent is not available
        }
    }

    console.log('convo', company)

    const blobToFile = (blob, filename) => {
        return new File([blob], filename, { type: 'application/pdf' })
    }

    const [uploadMessage, setUploadMessage] = useState('')
    const [aiSummary, setAiSummary] = useState()

    const sendPDFToBackend = (fileObject) => {
        setSummarizing(true) // Start summarizing

        let eventPayload = {
            fistname: company.firstname, // Include event name in all types
            convoContainer: convoContainer, // Add snapintel if type is 3
            // chatroom: event.chatroom,
        }

        // Send the event payload to the backend
        axios
            .post(
                'https://test-v1.nexusbrain-core-ai-api.nbtechbeta.com/api/summarize/summarize_text',
                eventPayload
            )
            .then((response) => {
                console.log('PDF successfully sent to the server:', response)
                setUploadMessage('PDF uploaded successfully!')
                setAiSummary(response.data)
            })
            .catch((error) => {
                console.error('Error uploading the PDF:', error)
                setUploadMessage('Error uploading PDF. Please try again.')
            })
            .finally(() => {
                setSummarizing(false) // Reset summarizing state
            })
    }

    const makeUrlsClickable = (text) => {
        if (!text) {
            return { __html: '' }
        }

        const urlRegex = /(https?:\/\/[^\s]+)/g

        const replacedText = text.replace(
            urlRegex,
            (url) =>
                `<a class="hover:text-blue-600 hover:underline break-all font-bold" href="${url}" target="_blank"><br/> <br/> ${url} <br/> <br/></a>`
        )

        return { __html: replacedText }
    }

    const makeUrlsClickableAi = (text) => {
        // Replace URLs with anchor tags
        const urlRegex =
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
        text = text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>')

        // Replace newlines with <br />
        text = text.replace(/\n/g, '<br />')

        return { __html: text }
    }

    return (
        <div className=" top-0 bottom-0  right-0 w-full h-screen bg-white z-50 pt-4 flex flex-col justify-start items-center">
            <div className="w-full flex flex-col items-center justify-between px-5 sticky top-0 bg-white pt-2 shadow-md">
                {loading || summarizing ? (
                    // <DownloadProgress />
                    <DownloadProgress progress={loading} isSummarizing={summarizing} />
                ) : (
                    <div className="w-full flex items-center justify-between gap-4">
                        {/* {uploadMessage && <div className="notification">{uploadMessage}</div>} */}
                        <div className="flex gap-2">
                            <button
                                onClick={exportPDF}
                                className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                            >
                                Download PDF
                            </button>

                            <button
                                onClick={sendPDFToBackend}
                                className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                                disabled={summarizing} // Disable button while summarizing
                            >
                                {summarizing ? 'Summarizing...' : 'AI Summarize'}
                            </button>
                        </div>

                        <p
                            className="text-2xl opacity-70 hover:opacity-100 items-center text-main cursor-pointer"
                            onClick={() => onClose()}
                        >
                            <AiOutlineCloseCircle />
                        </p>
                    </div>
                )}
                <p className="text-xs text-main pt-1 opacity-70 font-semibold">Preview</p>
            </div>
            <PDFExport
                fileName={`Export General SnapIntel - ${user} of ${company?.firstname}`}
                ref={pdfExportComponent}
                paperSize="A4"
            >
                <div className=" pt-10 px-5 text-main flex gap-5 ">
                    <div>
                        <img src={NexusLogo} alt="NexusLogo" className="w-10" />
                    </div>
                    <div>
                        <p className="text-lg h-fit font-bold -mt-2">{company?.firstname}</p>
                        <p className="text-md h-fit ">General SnapIntel of {user}</p>
                    </div>
                </div>
                <div className="h-full overflow-y-auto leading-3 pt-5 pb-10 ">
                    {convoContainer && convoContainer?.length > 0 ? (
                        convoContainer &&
                        convoContainer.map((convo, index) => (
                            <div className=" ">
                                <div className="text-sm font-bold w-full cursor-pointer hover:scale-105 transition-all duration-150 text-gray-600 px-5 my-2">
                                    <Divider>{convo?.date}</Divider>
                                </div>

                                {convo?.responses &&
                                    convo?.responses
                                        //slice(0,7).
                                        .map((question, questionIndex) => (
                                            <div>
                                                <div className="px-2">
                                                    <p className="text-[.7em] transition-all duration-300 px-4 py-2 w-90% text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                                                        {question?.question?.question}
                                                    </p>
                                                    <div className="w-full flex flex-col justify-end items-end my-2">
                                                        <p className=" w-[80%] borderf self-end text-[.7em] bg-white border px-4 py-2 shadow-sm rounded-md bg-opacity-70">
                                                            <p
                                                                className="whitespace-break-spaces"
                                                                dangerouslySetInnerHTML={makeUrlsClickable(
                                                                    question?.answer
                                                                )}
                                                            />

                                                            {question?.attachments &&
                                                                question?.attachments.length >
                                                                    0 && (
                                                                    <Fragment>
                                                                        {question?.attachments.map(
                                                                            (attachment) => (
                                                                                <div
                                                                                    className="flex items-start mt-2 justify-start w-full"
                                                                                    key={
                                                                                        attachment.id
                                                                                    }
                                                                                >
                                                                                    {attachment.type ===
                                                                                    'image' ? (
                                                                                        // <Link
                                                                                        //     to={
                                                                                        //         attachment.filename
                                                                                        //     }
                                                                                        //     key={
                                                                                        //         attachment.id
                                                                                        //     }
                                                                                        //     className="font-bold "
                                                                                        // >
                                                                                        //     Image Link :{' '}
                                                                                        //     <p className="font-normal break-all text-blue-500 flex hover:underline hover:font-semibold">
                                                                                        //         <span>
                                                                                        //             <FaFileImage />
                                                                                        //         </span>
                                                                                        //         Click to
                                                                                        //         view
                                                                                        //     </p>
                                                                                        // </Link>
                                                                                        <div>
                                                                                            <img
                                                                                                alt={
                                                                                                    attachment.filename
                                                                                                }
                                                                                                src={
                                                                                                    attachment.filename
                                                                                                }
                                                                                                className="max-h-60"
                                                                                            />
                                                                                            <Link
                                                                                                to={
                                                                                                    attachment.filename
                                                                                                }
                                                                                                key={
                                                                                                    attachment.id
                                                                                                }
                                                                                                target="_blank"
                                                                                                className="font-bold mt-2 flex"
                                                                                            >
                                                                                                Image
                                                                                                Link
                                                                                                :{' '}
                                                                                                <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                    Click
                                                                                                    to
                                                                                                    view
                                                                                                </p>
                                                                                            </Link>
                                                                                        </div>
                                                                                    ) : attachment.type ===
                                                                                      'file' ? (
                                                                                        <Link
                                                                                            to={
                                                                                                attachment.filename
                                                                                            }
                                                                                            target="_blank"
                                                                                            className="font-bold mb-4"
                                                                                        >
                                                                                            PDF Link
                                                                                            :{' '}
                                                                                            <span className="font-normal break-all text-blue-500 flex items-center hover:underline hover:font-semibold">
                                                                                                <span>
                                                                                                    <img
                                                                                                        alt="PDF Display"
                                                                                                        className="h-8"
                                                                                                        src={
                                                                                                            PDFIcon
                                                                                                        }
                                                                                                    />
                                                                                                </span>
                                                                                                Go
                                                                                                to
                                                                                                link
                                                                                            </span>
                                                                                        </Link>
                                                                                    ) : null}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </Fragment>
                                                                )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                            </div>
                        ))
                    ) : (
                        <div className="w-full h-full flex flex-col justify-center items-center ">
                            <p className="text-xl text-main">
                                <RiMessageFill color="#7cc576" />
                            </p>
                            <p className="text-lg text-gray-600">No Record</p>
                        </div>
                    )}
                    <div className="w-full border-t border-main border-opacity-40 mb-8 p-2 pt-5">
                        {/* <p className="text-main font-bold text-md w-full  justify-between flex"> */}
                        {aiSummary && aiSummary.status === 'success' ? (
                            <>
                                <p className="text-main font-bold text-lg w-full  justify-between flex">
                                    AI Summary of {company.firstname}
                                </p>
                                <div
                                    className="text-sm text-justify px-2 pt-2"
                                    dangerouslySetInnerHTML={makeUrlsClickableAi(aiSummary.summary)}
                                />
                            </>
                        ) : (
                            <p>No summary available.</p>
                        )}{' '}
                        {/* </p> */}
                    </div>
                </div>
            </PDFExport>
        </div>
    )
}

export default GeneralSnapIntelExport
