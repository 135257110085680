import { axiosInstance } from 'services/axios'

export const getExportResponses = async (data) => {
    const response = await axiosInstance
        .post(
            '/api/v1/export_responses',
            {
                question_id: data.question_id,
                event_id: data.event_id,
                type: data.type,
            },
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
                'download',
                `Export ${
                    data.type == 0 && data.question_id == null
                        ? 'General Discussion'
                        : data.type == 0
                        ? 'Chatroom'
                        : data.type == 1
                        ? 'My KIQs'
                        : data.type == 2
                        ? 'SnapIntel'
                        : ''
                } Responses File.xlsx`
            )
            document.body.appendChild(link)
            link.click()
        })
        .catch((e) => {
            console.log(e)
        })

    return response
}

export const getExportGenSnapCSV = async (userID, companyID) => {
    const response = await axiosInstance
        .post(
            '/api/v1/export_gen_snapintel',
            {
                user_id: userID,
                company_id: companyID,
            },
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'General SnapIntel.xlsx')
            document.body.appendChild(link)
            link.click()
        })
        .catch((e) => {
            console.log(e)
        })

    return response
}

export const getExportSelectedCompany = async (api, data, callback, tabname) => {
    const response = await axiosInstance
        .post(api, data, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
            },
            onDownloadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                )
                callback({ onDownloadProgress: percentCompleted })
            },
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', tabname)
            document.body.appendChild(link)
            link.click()
        })
        .catch((e) => {
            console.log(e)
        })

    return response
}

export const getExportSelectedEvent = async (data) => {
    const response = await axiosInstance
        .post(
            '/api/v1/export_entries',
            {
                event_id: data.id,
            },
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `Number of Entries of ${data.event_name} .xlsx`)
            document.body.appendChild(link)
            link.click()
        })
        .catch((e) => {
            console.log(e)
        })

    return response
}

export const getExportNumEntriesResponses = async (data, agent, selectedEvent) => {
    const response = await axiosInstance
        .post(
            '/api/v1/export_no_of_entries',
            {
                user_id: agent.id,
                event_id: data[0].event_id,
                type: data[0].type,
                is_gd: 0,
                is_csv: 1,
            },
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
                'download',
                `Export Number of Entries of ${agent.fullname} in ${selectedEvent.event_name} Responses File.xlsx`
            )
            document.body.appendChild(link)
            link.click()
        })
        .catch((e) => {
            console.log(e)
        })

    return response
}

export const getExportNumEntriesGDResponses = async (data, agent, selectedEvent) => {
    const response = await axiosInstance
        .post(
            '/api/v1/export_no_of_entries',
            {
                user_id: agent.id,
                event_id: data[0].event_id,
                type: data[0].type,
                is_gd: 1,
                is_csv: 1,
            },
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
                'download',
                `Export Number of Entries of ${agent.fullname} in ${selectedEvent.event_name} Responses File.xlsx`
            )
            document.body.appendChild(link)
            link.click()
        })
        .catch((e) => {
            console.log(e)
        })

    return response
}

export const getExportEventsList = async () => {
    const response = await axiosInstance
        .post('/api/v1/export_events', null, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `Export Events List.xlsx`)
            document.body.appendChild(link)
            link.click()
        })
        .catch((e) => {
            console.log(e)
        })

    return response
}

export const getEventDocxFile = async (data, onDownloadProgress) => {
    try {
        const response = await axiosInstance.post(
            '/api/v1/export_events_word',
            {
                event_id: data.event,
                intel_type: data.intel_type,
                order_type: data.order_type,
                user_id: data.user_id,
            },
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                },
                onDownloadProgress,
            }
        )

        const blob = new Blob([response.data], {
            type: 'application/vnd.google-apps.document',
        })

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Export Event of ${data.event_name} Responses File.docx`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        return true
    } catch (e) {
        console.error('Error exporting DOCX:', e)
        return null
    }
}

export const getEventPdfFile = async (data, onDownloadProgress, setPdfPreview) => {
    try {
        const response = await axiosInstance.post(
            '/api/v1/export_events_pdf',
            {
                event_id: data.event,
                user_id: data.user_id, // Optional
                intel_type: data.intel_type, // Optional array
                order_type: data.order_type || 'asc', // Default to 'asc' if not provided
            },
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                },
                onDownloadProgress,
            }
        )

        const blob = new Blob([response.data], {
            type: 'application/pdf',
        })

        const pdfURL = window.URL.createObjectURL(blob)

        // Set the PDF URL in a state to display the preview
        setPdfPreview(pdfURL)

        // Do not return the download functionality to prevent automatic download
    } catch (e) {
        console.error('Error exporting PDF:', e)
        return null
    }
}

export const getGenSnapDocxFile = async (data, onDownloadProgress) => {
    try {
        const response = await axiosInstance.post(
            '/api/v1/export_general_snapintel_word',
            {
                company_id: data.company_id,
                user_id: data.user_id,
            },
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                },
                onDownloadProgress,
            }
        )

        const blob = new Blob([response.data], {
            type: 'application/vnd.google-apps.document',
        })

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Export Event of ${data.firstname} Responses File.docx`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        return true
    } catch (e) {
        console.error('Error exporting DOCX:', e)
        return null
    }
}

export const getGenSnapPdfFile = async (data, onDownloadProgress, setPdfPreview) => {
    try {
        const response = await axiosInstance.post(
            '/api/v1/export_general_snapintel_pdf',
            {
                company_id: data.company_id,
                user_id: data.user_id,
            },
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                },
                onDownloadProgress,
            }
        )

        const blob = new Blob([response.data], {
            type: 'application/pdf',
        })

        const pdfURL = window.URL.createObjectURL(blob)

        // Set the PDF preview in the state for display
        setPdfPreview(pdfURL)

        // Return a function to download the PDF if needed after preview
        return () => {
            const link = document.createElement('a')
            link.href = pdfURL
            link.download = `Export General SnapIntel of ${data.firstname} Responses File.pdf`
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    } catch (e) {
        console.error('Error exporting PDF:', e)
        return null
    }
}
