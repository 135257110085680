import React, { useEffect, useState } from 'react'
import Papa from 'papaparse'
import companies from '../../components/data/companies.json'
import DataTable from 'react-data-table-component'
import { TbTableExport } from 'react-icons/tb'
import MainNav from 'components/MainNav'
import { useMutation, useQueries } from 'react-query'
import {
    getAllPDFCompaniesApi,
    getCompaniesApi,
    getCompaniesQuestionApi,
    getCompaniesResponsesApi,
    getPDFCompaniesApi,
} from 'services/request/Companies'
import { LoadingComponent } from 'components/LoadingComponent'
import { getChatroomApi } from 'services/request/Dashboard'
import CompaniesGeneralDiscussion from 'components/ChatSelection/CompaniesGeneralDiscussion'
import { IoCloseOutline } from 'react-icons/io5'
import ExportCompaniesKIQs from 'components/Exports/ExportCompaniesKIQs'
import CompaniesExport from 'components/Exports/ExportCompanies'
import {
    DownloadDocxButton,
    DownloadPDFButton,
} from 'components/Buttons/COmpaniesDownoloadPDFButton'
import ButtonGroup from 'components/Buttons/CompaniesKiqsBUtton'
import EventQuestionsComponent from 'components/Buttons/CompaniesQuestionList'
import { MdOutlineFileDownload } from 'react-icons/md'
import { getEventDocxFile, getEventPdfFile } from 'services/request/Exports'
import { DownloadProgress } from 'components/DownloadingComponent'
import CompaniesChat from 'components/ChatSelection/CompaniesChat'
import PdfPreview from 'components/Preview/PdfPreview'

function Companies() {
    const [search, setSearch] = useState('')
    const [filteredData, setFilteredData] = useState()
    const [selectedEvent, setSelectedEvent] = useState([0])
    const [eventQuestions, setEventQuestions] = useState([])
    const [questionResponses, setQuestionResponses] = useState(0)
    const [selectedType, setTypeSelected] = useState()
    const [kiqsSelected, setKiqsSelected] = useState('')
    const [dataCon, setDataCon] = useState([0])
    const [selectedButton, setSelectedButton] = useState(null)
    const [companyToggles, setCompanyToggles] = useState(false)
    const [generalDiscussionToggle, setGeneralDiscussionToggle] = useState()
    const [openPopupIndex, setOpenPopupIndex] = useState(null)
    const [openPopupIndexDocx, setOpenPopupIndexDocx] = useState(null)
    const [isDownload, setIsDownload] = useState(false)
    const [isDownloadCompany, setIsDownloadCompany] = useState(false)
    const [dataEvent, setDataEvent] = useState(null)
    const [exportKIQType, setExportKIQType] = useState()
    const [pdfAllKIQS, setPdfAllKIQS] = useState()
    const [selectedQuestion, setSelectedQuestion] = useState([])
    const [attachments, setAttachments] = useState()
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState()

    const arrayRes = useQueries([
        {
            queryKey: ['getChatroomApi', dataEvent],
            queryFn: () => getChatroomApi(dataEvent.id),
            onSuccess: (data) => {
                setAttachments(data.attachments)
            },

            // enabled: kiqsView === 0 || kiqsView === 3,
        },
        {
            queryKey: ['getCompaniesApi', 1],
            queryFn: () => getCompaniesApi(),
            onSuccess: (data) => {
                setFilteredData(data.result)
            },
        },
        {
            queryKey: ['getCompaniesQuestionApi', 2, selectedEvent, kiqsSelected],
            queryFn: () => getCompaniesQuestionApi(selectedEvent.event_id),
            onSuccess: (data) => {
                setEventQuestions(data.result)
                // arrayRes[1].refetch()
                // setGeneralDiscussionToggle(data.result.general_discussion)
            },
            enabled: !!selectedEvent,
        },
        {
            queryKey: ['getCompaniesResponsesApi', 3, selectedQuestion],
            queryFn: () =>
                getCompaniesResponsesApi(selectedQuestion.id, selectedEvent.event_id, selectedType),
            onSuccess: (data) => {
                setQuestionResponses(data.result)
            },
            enabled: selectedQuestion?.length !== 0,
        },
    ])
    const handleSearchChange = (value) => {
        setSearch(value)

        const newFilteredData = companies.result.filter(
            (user) =>
                value.trim() === '' ||
                companyColumns.some((column) => {
                    if (typeof column.selector === 'function') {
                        const selectedValue = column.selector(user)
                        if (typeof selectedValue === 'string') {
                            return selectedValue.toLowerCase().includes(value.toLowerCase())
                        }
                    }
                    return false
                })
        )
        setFilteredData(newFilteredData)
    }

    const exportData = (data, fileName) => {
        const transformedData = data.map((row) => ({
            Company: row.company_name,
            Events: row.events && row.events.map((event) => event.event_name).join(', '),
        }))

        const csv = Papa.unparse(transformedData)
        const exportedDataBlob = new Blob([csv], {
            type: 'text/csv;charset=utf-8;',
        })
        const downloadLink = document.createElement('a')
        downloadLink.href = window.URL.createObjectURL(exportedDataBlob)
        downloadLink.download = fileName
        downloadLink.click()
    }

    const exportAllData = () => {
        exportData(filteredData, 'Companies_all_data.csv') // Use filteredData here
    }

    const exportFilteredData = () => {
        exportData(filteredData, 'Companies_filtered_data.csv') // Use filteredData here
    }

    const handleCompanySelect = (row) => {
        setDataCon(row)
        setSelectedQuestion([0])
        setSelectedEvent([0])
        setKiqsSelected('')
        setGeneralDiscussionToggle(null)

        setCompanyToggles((prevToggles) => {
            const updatedToggles = Object.fromEntries(
                Object.keys(prevToggles).map((companyName) => [
                    companyName,
                    companyName === row.company_name,
                ])
            )

            updatedToggles[row.company_name] = !prevToggles[row.company_name]

            return updatedToggles
        })
    }

    const handleKIQSClicks = (event, kiqs, type) => {
        setSelectedEvent(event)
        setSelectedButton(type)
        setKiqsSelected(kiqs)
        setTypeSelected(type)
        setQuestionResponses([])
        setSelectedQuestion([])
        setGeneralDiscussionToggle(null)
    }

    const handleGDKIQSClicks = (event, kiqs, type) => {
        setSelectedEvent(event)
        setGeneralDiscussionToggle(kiqs === 'General Discussion')
        setSelectedButton(type)
        setKiqsSelected(kiqs)
        setTypeSelected(type)
        setQuestionResponses([])
        setSelectedQuestion([])
    }
    const handleQuestionSelect = (question) => {
        setSelectedQuestion(question)
        setGeneralDiscussionToggle(0)
    }

    // const handleDownloadPDF = (event, eventKey) => {
    //     setSelectedEvent(event)

    //     setOpenPopupIndex((prevIndex) => (prevIndex === eventKey ? null : eventKey))
    // }
    const handleDownloadDOCX = (event, eventKey) => {
        setSelectedEvent(event)

        setOpenPopupIndexDocx((prevIndex) => (prevIndex === eventKey ? null : eventKey))
    }

    const handleDownloadDocx = async (eventId, type, order) => {
        try {
            setLoading(true)

            await getEventDocxFile({
                event: eventId,
                intel_type: type,
                order_type: order,
                event_name: selectedEvent.event_name,
                onDownloadProgress: (data) => {
                    setProgress(data)
                },
            })

            setLoading(false)
        } catch (e) {
            console.error('Error while downloading DOCX:', e)
            setLoading(false)
        }
    }
    const [pdfPreview, setPdfPreview] = useState(null)
    const handleDownloadPDF = async (eventId, type, order) => {
        try {
            setLoading(true)

            await getEventPdfFile(
                {
                    event: eventId,
                    intel_type: type,
                    order_type: order,
                    event_name: selectedEvent.event_name,
                },
                null,
                setPdfPreview
            )

            setLoading(false)
        } catch (e) {
            console.error('Error while downloading PDF:', e)
            setLoading(false)
        }
    }
    const handleClose = () => {
        setPdfPreview(null)
    }
    const companyColumns = [
        {
            name: 'Company',
            sortable: true,
            selector: (row) => row.company_name,
            cell: (row) => (
                <div className="w-full h-fit py-2">
                    <p
                        onClick={() => handleCompanySelect(row)}
                        className={`cursor-pointer select-none ${
                            dataCon && dataCon.company_name === row.company_name
                                ? 'font-bold hover:font-bold'
                                : ''
                        }`}
                    >
                        {row.company_name}
                    </p>
                    {companyToggles[row.company_name] && (
                        <div className="w-full flex flex-col gap-2 bg-white shadow-sm md:w-full sm:w-full xs:w-full rounded-md overflow-hidden">
                            {pdfPreview && (
                                <PdfPreview
                                    handleClose={handleClose}
                                    dataEvent={selectedEvent}
                                    pdfPreview={pdfPreview}
                                    loading={loading}
                                />
                            )}
                            <div className="w-full h-fit">
                                <div className="w-full text-sm flex px-5 pt-5 pb-2 border-b border-main rounded-md overflow-hidden">
                                    <p className="w-1/2 font-semibold text-main">
                                        {dataCon[0] === 0
                                            ? 'Select a Company'
                                            : `Events of ${dataCon.company_name}`}
                                    </p>
                                </div>
                                <div className="w-full flex flex-wrap p-2 h-fit max-h-60 overflow-y-auto">
                                    {dataCon.events.length === 0 ? (
                                        <span> There are no events in this company</span>
                                    ) : (
                                        <ol className="px-8 w-full flex flex-col gap-2 overflow-hidden">
                                            {dataCon.events &&
                                                dataCon.events.map((event, eventKey) => (
                                                    <div className="flex flex-col w-full justify-between border-b pb-2">
                                                        <div className="w-full flex justify-between">
                                                            <li
                                                                // onClick={() => setSelectedEvent(event)}
                                                                key={eventKey}
                                                                className={`${
                                                                    event?.event_name ===
                                                                        selectedEvent?.event_name &&
                                                                    'font-bold underline'
                                                                } w-fit py-2 h-full items-center flex text-sm`}
                                                            >
                                                                {event.event_name}
                                                            </li>
                                                            <div className="gap-3 flex">
                                                                {loading ? (
                                                                    <DownloadProgress
                                                                        progress={progress}
                                                                    />
                                                                ) : (
                                                                    <DownloadDocxButton
                                                                        event={event}
                                                                        eventKey={eventKey}
                                                                        handleDownloadDOCX={
                                                                            handleDownloadDOCX
                                                                        }
                                                                        openPopupIndexDocx={
                                                                            openPopupIndexDocx
                                                                        }
                                                                        setOpenPopupIndexDocx={
                                                                            setOpenPopupIndexDocx
                                                                        }
                                                                        handleDownloadDocx={
                                                                            handleDownloadDocx
                                                                        }
                                                                        selectedEvent={
                                                                            selectedEvent
                                                                        }
                                                                    />
                                                                )}

                                                                <div>
                                                                    {/* <DownloadPDFButton
                                                                        event={event}
                                                                        eventKey={eventKey}
                                                                        handleDownloadPDF={
                                                                            handleDownloadPDF
                                                                        }
                                                                        exportPDFConvo={
                                                                            exportPDFConvo
                                                                        }
                                                                        setExportKIQType={
                                                                            setExportKIQType
                                                                        }
                                                                        exportPDFAllKIQS={
                                                                            exportPDFAllKIQS
                                                                        }
                                                                        setIsDownloadCompany={
                                                                            setIsDownloadCompany
                                                                        }
                                                                        openPopupIndex={
                                                                            openPopupIndex
                                                                        }
                                                                        setOpenPopupIndex={
                                                                            setOpenPopupIndex
                                                                        }
                                                                    /> */}
                                                                    <button
                                                                        onClick={() =>
                                                                            setOpenPopupIndex(
                                                                                openPopupIndex ===
                                                                                    eventKey
                                                                                    ? null
                                                                                    : eventKey
                                                                            )
                                                                        }
                                                                        className="text-xs relative whitespace-nowrap flex items-center gap-2 h-fit cursor-pointer border-main rounded-md border py-2 px-4 my-2"
                                                                    >
                                                                        PDF
                                                                        <span>
                                                                            <MdOutlineFileDownload />
                                                                        </span>
                                                                        {openPopupIndex ===
                                                                            eventKey && (
                                                                            <div className="animate_fade_up z-0 mt-[5rem] -ml-2 absolute transition-all duration-300 cursor-pointer hover:bg-opacity-100 flex flex-col text-left rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main">
                                                                                <button
                                                                                    className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                                                                    onClick={() =>
                                                                                        handleDownloadPDF(
                                                                                            selectedEvent?.event_id,
                                                                                            [
                                                                                                'General Discussions',
                                                                                            ],
                                                                                            'desc'
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    General
                                                                                    Discussions PDF{' '}
                                                                                    <MdOutlineFileDownload className="text-lg" />
                                                                                </button>
                                                                                <button
                                                                                    className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                                                                    onClick={() => {
                                                                                        handleDownloadPDF(
                                                                                            selectedEvent?.event_id,
                                                                                            [
                                                                                                'Chatroom KIQs',
                                                                                            ],
                                                                                            'desc'
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    Chatroom PDF{' '}
                                                                                    <span className="text-lg">
                                                                                        <MdOutlineFileDownload />
                                                                                    </span>
                                                                                </button>

                                                                                <button
                                                                                    className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                                                                    onClick={() => {
                                                                                        handleDownloadPDF(
                                                                                            selectedEvent?.event_id,
                                                                                            [
                                                                                                'My KIQs',
                                                                                            ],
                                                                                            'desc'
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    My KIQs PDF{' '}
                                                                                    <span className="text-lg">
                                                                                        <MdOutlineFileDownload />
                                                                                    </span>
                                                                                </button>
                                                                                <button
                                                                                    className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                                                                    onClick={() => {
                                                                                        handleDownloadPDF(
                                                                                            selectedEvent?.event_id,
                                                                                            [
                                                                                                'SnapIntel',
                                                                                            ],
                                                                                            'desc'
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    SnapIntel PDF{' '}
                                                                                    <span className="text-lg">
                                                                                        <MdOutlineFileDownload />
                                                                                    </span>
                                                                                </button>
                                                                                <button
                                                                                    className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                                                                    onClick={() =>
                                                                                        handleDownloadPDF(
                                                                                            selectedEvent?.event_id,
                                                                                            [
                                                                                                'General Discussions',
                                                                                                'Chatroom KIQs',
                                                                                                'My KIQs',
                                                                                                'SnapIntel',
                                                                                            ],
                                                                                            'desc'
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Event PDF{' '}
                                                                                    <span className="text-lg">
                                                                                        <MdOutlineFileDownload />
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="w-full flex gap-2 ">
                                                            <ButtonGroup
                                                                event={event}
                                                                handleGDKIQSClicks={
                                                                    handleGDKIQSClicks
                                                                }
                                                                handleKIQSClicks={handleKIQSClicks}
                                                                selectedButton={selectedButton}
                                                                selectedEvent={selectedEvent}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                        </ol>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ),
        },
    ]

    const { mutate: PDFConvo } = useMutation((data) => getPDFCompaniesApi(data), {
        onSuccess: (res) => setDataEvent(res.result),
        onError: (res) => console.log(res),
    })

    const exportPDFConvo = (event, type) => {
        PDFConvo({ event, type })
        setIsDownload(true)
    }

    const { mutate: PDFAllKIQS } = useMutation((data) => getAllPDFCompaniesApi(data), {
        onSuccess: (res) => {
            setPdfAllKIQS(res.result)
            setIsDownloadCompany(true)
        },
        onError: (res) => console.log(res),
    })
    const exportPDFAllKIQS = (event) => {
        PDFAllKIQS(event)
    }
    useEffect(() => {
        function handleClickOutside(event) {
            const modal = document.querySelector('.modal')
            if (modal && !modal.contains(event.target)) {
                setIsDownload(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [setIsDownload])
    return (
        <div className=" flex flex-col w-screen h-full overflow-x-hidden">
            {isDownload && (
                <div className="w-[50%] sm:w-full md:w-[50%] fixed right-0 top-8 bottom-0 z-30 h-screen custom-scroll bg-white animate_move_to_Left shadow-md">
                    <ExportCompaniesKIQs
                        event={selectedEvent}
                        data={dataEvent}
                        type={exportKIQType}
                        onClose={() => setIsDownload(false)}
                    />
                </div>
            )}

            {isDownloadCompany && (
                <div className="w-[50%] sm:w-full md:w-[50%] fixed right-0 top-8 bottom-0 z-30 h-screen custom-scroll bg-white animate_move_to_Left shadow-md">
                    <CompaniesExport
                        dataCon={pdfAllKIQS}
                        event={selectedEvent}
                        onClose={() => setIsDownloadCompany(false)}
                    />
                </div>
            )}
            <div className="opacity-50 fixed w-full h-full bg-dashboard bg-[length:600px_300px] bg-center -z-10 brightness-[.8]"></div>
            <div className="w-full h-full flex gap-5 py-5 mb-10">
                <div className="w-full h-fit rounded-lg custom-scroll flex flex-col gap-4">
                    <div className="w-full px-5">
                        <MainNav />
                    </div>

                    <div className="w-full h-fit flex justify-between px-5 items-center sm:flex-col">
                        <p className="sm:text-center font-bold text-2xl sm:pb-3">
                            Companies & Events : Questions and Responses
                        </p>
                        <div className="sm:flex-col sm:w-full flex w-2/4 items-center justify-center h-full gap-2">
                            <div className="w-full h-fit flex  items-center justify-center">
                                <input
                                    onChange={(e) => handleSearchChange(e.target.value)}
                                    type="text"
                                    value={search}
                                    className="sm:w-full border border-gray-400 rounded-md placeholder:text-sm pl-3 p-1 flex grow bg-white bg-opacity-60 outline-none"
                                    placeholder="Search User..."
                                />

                                {search.length > 0 && (
                                    <div
                                        onClick={() => handleSearchChange('')}
                                        className="w-fit px-4 py-2 flex bg-main text-white font-bold scale-105 bg-opacity-60 rounded-r-lg border-main cursor-pointer"
                                    >
                                        <IoCloseOutline />
                                    </div>
                                )}
                            </div>
                            {search.length === 0 && (
                                <button
                                    className="  bg-main px-3 py-1 rounded-md flex items-center text-white whitespace-nowrap"
                                    onClick={exportAllData}
                                >
                                    CSV{' '}
                                    <span className="text-lg">
                                        <MdOutlineFileDownload />
                                    </span>
                                </button>
                            )}
                            <button
                                className={`${
                                    search.length === 0 ? 'hidden' : ''
                                } text-lg bg-main px-3 py-2 rounded-md flex items-center gap-2 text-white whitespace-nowrap`}
                                disabled={search.length === 0}
                                onClick={exportFilteredData}
                            >
                                <TbTableExport /> <span className="text-sm">Export Filtered</span>
                            </button>
                        </div>
                    </div>

                    <div className="w-full h-full custom-header px-4 flex gap-4 md:flex-col sm:flex-col">
                        <div className="w-[38%] h-fit  flex flex-col  md:w-full sm:w-full xs:w-full rounded-md overflow-hidden">
                            <DataTable
                                progressComponent={<LoadingComponent title={'Companies'} />}
                                // progressPending={arrayRes[0].isLoading}
                                columns={companyColumns}
                                data={filteredData}
                                pagination
                                fixedHeader
                            />
                        </div>

                        {!kiqsSelected == '' ? (
                            <div className="transition-all duration-300 w-[62%] mr-2  max-h-[700px] sm:h-fit bg-white  flex rounded-md md:w-full sm:w-full md:flex-row sm:flex-col">
                                <EventQuestionsComponent
                                    selectedEvent={selectedEvent}
                                    kiqsSelected={kiqsSelected}
                                    arrayRes={arrayRes}
                                    eventQuestions={eventQuestions}
                                    handleQuestionSelect={handleQuestionSelect}
                                />
                                {generalDiscussionToggle ? (
                                    <CompaniesGeneralDiscussion
                                        isLoading={arrayRes[1].isLoading}
                                        generalDiscussionToggle={eventQuestions?.general_discussion}
                                        selectedEvent={selectedEvent}
                                        selectedQuestion={selectedQuestion}
                                    />
                                ) : (
                                    <div className="flex grow">
                                        <CompaniesChat
                                            selectedQuestion={selectedQuestion}
                                            selectedEvent={selectedEvent}
                                            questionResponses={questionResponses}
                                            isLoading={arrayRes[2].isLoading}
                                        />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="py-5 w-[62%] mr-2 h-fit bg-white overflow-auto flex justify-center items-center rounded-md md:w-full sm:w-full">
                                <div className="w-fit h-fit">
                                    <p className="text-lg text-grey font-bold">
                                        1. Select a Company
                                    </p>
                                    <p className="text-lg text-grey font-bold">
                                        2. Select an Event
                                    </p>
                                    <p>
                                        <span className="text-lg text-grey font-bold">
                                            3. Select a Kiqs Type
                                        </span>
                                        <div className="flex gap-2">
                                            <button className="animate-pulse duration-500 text-sm p-2 border rounded-sm bg-[#7cc576] text-white hover:bg-[#87d680]">
                                                Chatroom KIQs
                                            </button>
                                            <button className="animate-pulse duration-500 text-sm p-2 border rounded-sm bg-[#333333] text-white hover:bg-[#484848]">
                                                My KIQs
                                            </button>

                                            <button className="animate-pulse duration-500 text-sm p-2 border rounded-sm bg-main bg-opacity-25 text-main hover:bg-[#e0f7de]">
                                                SnapIntel
                                            </button>
                                        </div>
                                    </p>
                                    <span className="text-lg text-grey font-bold">
                                        4. Select a Questions
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Companies
