import React, { useState } from 'react'
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi'
import { MdOutlineSubdirectoryArrowLeft } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import PDFIcon from '../../assets/pdf.png'
import ImageView from 'components/ImageView'
import { IoChatboxEllipses } from 'react-icons/io5'
import { LoadingComponent } from 'components/LoadingComponent'
import { ChatMessage } from 'components/Responses/FilteredResponse'
import ResponseMessage from 'components/Responses/FilteredReplies'

function GeneralDiscussion({ dataContainer, isLoading }) {
    const [collapse, setCollapse] = useState(true)
    const [selectedImage, setSelectedImage] = useState()
    const [selectedResponse, setSelectedResponse] = useState(null)

    const [showChat, setShowChat] = useState(false)
    const toggleChat = (chatId) => {
        setShowChat((prev) => ({
            ...prev,
            [chatId]: !prev[chatId],
        }))
    }
    const [showReply, setShowReply] = useState({})

    const toggleReply = (replyIndex) =>
        setShowReply((prev) => ({
            ...prev,
            [replyIndex]: !prev[replyIndex],
        }))

    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 break-all hover:underline font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }
    return (
        <>
            {isLoading ? (
                <span>
                    <LoadingComponent title="General Discussion" />
                </span>
            ) : (
                <React.Fragment>
                    {dataContainer && dataContainer.general_discussion?.length === 0 ? (
                        <div className="text-center font-bold text-main py-4 border  border-main/70">
                            No General Discussion
                        </div>
                    ) : (
                        <React.Fragment>
                            {selectedImage != null && (
                                <ImageView
                                    image={selectedImage}
                                    onClose={() => setSelectedImage(null)}
                                />
                            )}
                            <div className="w-full flex flex-col h-full mb-10">
                                <div className="w-full bg-main text-md text-white px-4 py-2 text-center rounded-t-md">
                                    <span>GENERAL DISCUSSION</span>
                                </div>
                                <div className="w-full max-h-[500px] overflow-y-auto px-5 flex flex-col gap-3 bg-gray-200 pb-5 pt-2 rounded-b-md">
                                    <div className="w-full font-bold text-main pt-2 flex justify-between items-center">
                                        <div className="flex  gap-2">
                                            <span className="text-2xl">
                                                <IoChatboxEllipses />
                                            </span>
                                            <div className="flex gap-1 text-lg">
                                                <span>Total of </span>
                                                <span>
                                                    {dataContainer &&
                                                        dataContainer.general_discussion?.length}
                                                </span>
                                                <span> Responses </span>
                                            </div>
                                        </div>
                                        <div className="flex gap-4 ">
                                            <span>
                                                {dataContainer &&
                                                    dataContainer.general_discussion &&
                                                    dataContainer.general_discussion?.length >
                                                        0 && (
                                                        <p className="flex items-center justify-center text-main gap-2">
                                                            <span className=" text-4xl">
                                                                <BiSolidLike />
                                                            </span>
                                                            <span className="text-lg">
                                                                {dataContainer.general_discussion.reduce(
                                                                    (totalLikes, reactions) =>
                                                                        totalLikes +
                                                                        reactions.total_likes,
                                                                    0
                                                                )}
                                                            </span>
                                                        </p>
                                                    )}
                                            </span>
                                            <span>
                                                {dataContainer &&
                                                    dataContainer.general_discussion &&
                                                    dataContainer.general_discussion?.length >
                                                        0 && (
                                                        <p className="text-grey  flex items-center justify-center gap-2">
                                                            <span className=" text-4xl">
                                                                <BiSolidDislike />
                                                            </span>
                                                            <span className="text-lg">
                                                                {dataContainer.general_discussion.reduce(
                                                                    (totalDisLikes, reactions) =>
                                                                        totalDisLikes +
                                                                        reactions.total_dislikes,
                                                                    0
                                                                )}
                                                            </span>
                                                        </p>
                                                    )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-col gap-2">
                                        <div className="px-10 flex gap-2">
                                            <p
                                                className="font-bold text-sm cursor-pointer"
                                                onClick={() => setCollapse(!collapse)}
                                            ></p>
                                        </div>
                                        {collapse && (
                                            <React.Fragment>
                                                {dataContainer &&
                                                    dataContainer.general_discussion &&
                                                    dataContainer.general_discussion?.map(
                                                        (chat, chatIndex) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <div>
                                                                        <div>
                                                                            <span className="text-xs font-bold">
                                                                                {chat.user.fullname}
                                                                            </span>
                                                                        </div>
                                                                        <div className="text-xs gap-2 flex flex-col w-fit max-w-[70%] justify-start items-start text-left bg-white bg-opacity-30 py-2 pl-2 pr-4 rounded-l-md rounded-tr-md shadow-sm">
                                                                            <ChatMessage
                                                                                key={chat.id}
                                                                                chat={chat}
                                                                                showChat={showChat}
                                                                                toggleChat={
                                                                                    toggleChat
                                                                                }
                                                                                setSelectedImage={
                                                                                    setSelectedImage
                                                                                }
                                                                                makeUrlsClickable={
                                                                                    makeUrlsClickable
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="w-full flex flex-col gap-2">
                                                                        <div className="px-10 flex gap-2">
                                                                            {chat.responses
                                                                                ?.length > 0 && (
                                                                                <div className="flex">
                                                                                    <span className="rotate-90 text-base justify-center items-center ">
                                                                                        <MdOutlineSubdirectoryArrowLeft />
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            setSelectedResponse(
                                                                                                selectedResponse ===
                                                                                                    chat
                                                                                                    ? null
                                                                                                    : chat
                                                                                            )
                                                                                        }
                                                                                        className="cursor-pointer "
                                                                                    >
                                                                                        {selectedResponse ===
                                                                                        chat
                                                                                            ? 'Hide'
                                                                                            : 'Show'}{' '}
                                                                                        Replies
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        {selectedResponse ===
                                                                            chat &&
                                                                            chat.responses?.length >
                                                                                0 && (
                                                                                <React.Fragment>
                                                                                    <div className="flex flex-col w-full ml-5 px-6 animate_fade_up">
                                                                                        {chat.responses.map(
                                                                                            (
                                                                                                response
                                                                                            ) => (
                                                                                                <ResponseMessage
                                                                                                    key={
                                                                                                        response.id
                                                                                                    }
                                                                                                    response={
                                                                                                        response
                                                                                                    }
                                                                                                    showReply={
                                                                                                        showReply
                                                                                                    }
                                                                                                    toggleReply={
                                                                                                        toggleReply
                                                                                                    }
                                                                                                    setSelectedImage={
                                                                                                        setSelectedImage
                                                                                                    }
                                                                                                    makeUrlsClickable={
                                                                                                        makeUrlsClickable
                                                                                                    }
                                                                                                />
                                                                                            )
                                                                                        )}
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )}
                                                                    </div>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    )}
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </>
    )
}

export default GeneralDiscussion
