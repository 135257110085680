import React, { useRef, useState } from 'react'
import NexusLogo from '../../assets/NexusBrain.png'
import { PDFExport } from '@progress/kendo-react-pdf'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import ChatroomExport from './KIQSExportComponents/ChatroomExport'
import MyKIQSExport from './KIQSExportComponents/MyKIQSExport'
import SnapIntelExport from './KIQSExportComponents/SnapIntelExport'
import { DownloadProgress } from 'components/DownloadingComponent'
import axios from 'axios'

const ExportCompaniesKIQs = ({ event, data, type, onClose }) => {
    const pdfExportComponent = useRef(null)
    const [loading, setLoading] = useState(false)
    const [summarizing, setSummarizing] = useState(false)
    const [uploadMessage, setUploadMessage] = useState('')
    const [aiSummary, setAiSummary] = useState()

    const exportPDF = () => {
        setLoading(true) // Start loading
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save((pdfBlob) => {
                if (pdfBlob instanceof Blob) {
                    const fileObject = blobToFile(
                        pdfBlob,
                        `Export Spaces and Events - ${event?.event_name}.pdf`
                    )
                    sendPDFToBackend(fileObject) // No need for finally here
                } else {
                    console.error('The exported PDF is not a Blob:', pdfBlob)
                    setLoading(false) // Stop loading in case of error
                }
            })
        } else {
            setLoading(false) // Stop loading if pdfExportComponent is not available
        }
    }

    const blobToFile = (blob, filename) => {
        return new File([blob], filename, { type: 'application/pdf' })
    }
    const sendPDFToBackend = (fileObject) => {
        setSummarizing(true) // Start summarizing

        // Prepare the payload with conditional data based on the selected type
        let eventPayload = {
            event_name: event.event_name, // Include event name in all types
            ...(type === 0 && { chatroom: event.chatroom }), // Add chatroom if type is 1
            ...(type === 1 && { mykiqs: event.mykiqs }), // Add mykiqs if type is 2
            ...(type === 2 && { snapintel: event.snapintel }), // Add snapintel if type is 3
            // chatroom: event.chatroom,
        }

        // Send the event payload to the backend
        axios
            .post(
                'https://test-v1.nexusbrain-core-ai-api.nbtechbeta.com/api/summarize/summarize_text',
                eventPayload
            )
            .then((response) => {
                setUploadMessage('PDF uploaded successfully!')
                setAiSummary(response.data) // Handle AI summary response
            })
            .catch((error) => {
                setUploadMessage('Error uploading PDF. Please try again.')
            })
            .finally(() => {
                setSummarizing(false) // Reset summarizing state
            })
    }

    const makeUrlsClickableAi = (text) => {
        const urlRegex =
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
        text = text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>')
        text = text.replace(/\n/g, '<br />')
        return { __html: text }
    }

    return (
        <div className="top-0 bottom-0 right-0 w-full overflow-x-hidden h-screen bg-white z-50 pt-4 flex flex-col justify-start items-center">
            <div className="w-full flex flex-col items-center justify-between px-5 sticky -top-4 bg-white pt-4 shadow-md">
                {loading || summarizing ? (
                    <DownloadProgress progress={loading} isSummarizing={summarizing} />
                ) : (
                    <div className="w-full flex items-center justify-between gap-4">
                        <div className="flex gap-2">
                            <button
                                onClick={exportPDF}
                                className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                            >
                                Download PDF
                            </button>

                            {/* {(type === 1 || type === 2) && ( */}
                            <button
                                onClick={() => sendPDFToBackend()}
                                className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                                disabled={summarizing} // Disable button while summarizing
                            >
                                {summarizing ? 'Summarizing...' : 'AI Summarize'}
                            </button>
                            {/* )} */}
                        </div>

                        <p
                            className="text-2xl opacity-70 hover:opacity-100 items-center text-main cursor-pointer"
                            onClick={() => onClose()}
                        >
                            <AiOutlineCloseCircle />
                        </p>
                    </div>
                )}
                <p className="text-xs text-main pt-1 opacity-70 font-semibold">Preview</p>
            </div>

            <PDFExport
                fileName={`Export Companies - ${event?.event_name} - ${
                    type === 0
                        ? 'Chatroom'
                        : type === 1
                        ? 'My KIQs'
                        : type === 2
                        ? 'SnapIntel'
                        : null
                }`}
                ref={pdfExportComponent}
                paperSize="A4"
            >
                <div className="w-full flex flex-col px-4 py-2 ">
                    <div className="pt-2 px-5 text-main flex gap-5 items-center">
                        <div>
                            <img src={NexusLogo} alt="NexusLogo" className="w-10" />
                        </div>
                        <div>
                            <p className="text-md h-fit font-bold">{event?.event_name}</p>
                            <div>
                                {type === 0 ? (
                                    <p>Chatroom Export</p>
                                ) : type === 1 ? (
                                    <p>My KIQs Export</p>
                                ) : type === 2 ? (
                                    <p>SnapIntel Export</p>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-full">
                        {type === 0 ? (
                            <ChatroomExport
                                aiSummary={aiSummary}
                                makeUrlsClickableAi={makeUrlsClickableAi}
                                convo={data}
                            />
                        ) : type === 1 ? (
                            <MyKIQSExport
                                aiSummary={aiSummary}
                                makeUrlsClickableAi={makeUrlsClickableAi}
                                convo={data}
                            />
                        ) : type === 2 ? (
                            <SnapIntelExport
                                aiSummary={aiSummary}
                                makeUrlsClickableAi={makeUrlsClickableAi}
                                convo={data}
                            />
                        ) : null}
                    </div>
                </div>
            </PDFExport>
        </div>
    )
}

export default ExportCompaniesKIQs
