import React, { useState, useEffect, Fragment, useRef, useLayoutEffect } from 'react'
import { useQueries } from 'react-query'
import { getGeneralSnapIntelApi } from 'services/request/Dashboard'
import { NavLink } from 'react-router-dom'
import { IoIosArrowBack, IoMdPerson } from 'react-icons/io'
import PDFIcon from '../../assets/pdf.png'
import ImageView from 'components/ImageView'
import { UserResponse } from './GenSnapIntelModal/index'
import { FaBars, FaFileDownload } from 'react-icons/fa'
import { useAtomValue } from 'jotai'
import { authAtom } from 'store/authAtom'
import { BsPersonSquare } from 'react-icons/bs'
import Divider from '@mui/material/Divider'
import { RiMessageFill } from 'react-icons/ri'
import {
    getExportGenSnapCSV,
    getGenSnapDocxFile,
    getGenSnapPdfFile,
} from 'services/request/Exports'
import GeneralSnapIntelExport from 'components/Exports/GeneralSnapIntelExport'
import { DownloadProgress } from 'components/DownloadingComponent'
import PdfPreview from 'components/Preview/PdfPreview'
function GeneralSnapIntel() {
    // const { data } = useQuery(['getCompaniesApi'], getCompaniesApi)
    const { user } = useAtomValue(authAtom)
    const [companies, setCompanies] = useState()
    const [responseSort, setResponseSort] = useState()
    const [selectedImage, setSelectedImage] = useState()
    const [userResponse, setUserResponse] = useState()
    const [convoContainer, setConvoContainer] = useState()
    const [selectedCompany, setSelectedCompany] = useState()
    const [userCon, setUserCon] = useState('')
    const [userContainer, setUserContainer] = useState()
    const [companyToggle, setCompanyToggle] = useState(false)
    const [userID, setUserID] = useState(null)
    const [companyID, setCompanyID] = useState(null)
    const [exportGeneralSnapIntel, setExportGeneralSnapIntel] = useState(false)
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState()

    useEffect(() => {
        if (companies?.result && companies.result.length > 0) {
            handleCompany(companies.result[0])
        }
    }, [companies])

    const results = useQueries([
        {
            queryKey: ['getGeneralSnapIntelApi'],
            queryFn: () => getGeneralSnapIntelApi(),
            onSuccess: (data) => setCompanies(data),
        },
    ])

    const [questionToggles, setQuestionToggles] = useState(
        Array(convoContainer?.questions?.length).fill(false)
    )
    const handleQuestion = (companyIndex) => {
        setQuestionToggles((prevToggles) => {
            const newToggles = [...prevToggles]
            newToggles[companyIndex] = !prevToggles[companyIndex]
            return newToggles
        })
    }
    const [selectedCompanyIndex, setSelectedCompanyIndex] = useState(null)

    const handleCompany = (company, companyIndex) => {
        setCompanyToggle(!companyToggle)
        setSelectedCompany(company)
        setResponseSort(company.company)
        setUserContainer(company.company)

        setSelectedCompanyIndex((prevIndex) => (prevIndex === companyIndex ? null : companyIndex))
    }

    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }
    const exportCSV = (userID, companyID) => {
        getExportGenSnapCSV(userID, companyID)
    }
    const handleDownloadDocx = async (company_id, userId) => {
        try {
            setLoading(true)

            await getGenSnapDocxFile({
                company_id: company_id,
                user_id: userId,
                firstname: selectedCompany.firstname,
                onDownloadProgress: (data) => {
                    setProgress(data)
                },
            })

            setLoading(false)
        } catch (e) {
            console.error('Error while downloading DOCX:', e)
            setLoading(false)
        }
    }

    const [pdfPreview, setPdfPreview] = useState(null)

    const handleGeneratePDF = async (company_id, userId) => {
        try {
            setLoading(true)

            // Call the function with parameters and setPdfPreview
            await getGenSnapPdfFile(
                {
                    company_id: company_id,
                    user_id: userId,
                    firstname: selectedCompany.firstname,
                },
                null,
                setPdfPreview
            )

            setLoading(false)
        } catch (e) {
            console.error('Error generating PDF:', e)
            setLoading(false)
        }
    }

    const handleClosePreview = () => {
        setPdfPreview(null) // Close the PDF preview
    }

    return (
        <div className="h-full flex-col custom-scroll">
            {selectedImage != null && (
                <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
            )}
            {userResponse != null && (
                <UserResponse data={userResponse} onClose={() => setUserResponse(null)} />
            )}
            {exportGeneralSnapIntel && (
                <div className="w-[40%] sm:w-full fixed right-0 top-8 bottom-0 z-30 h-screen overflow-y-auto bg-white animate_move_to_Left">
                    <GeneralSnapIntelExport
                        user={userCon?.fullname}
                        company={selectedCompany}
                        data={convoContainer?.responses}
                        onClose={() => setExportGeneralSnapIntel(false)}
                    />
                </div>
            )}
            {pdfPreview && (
                <PdfPreview
                    handleClose={handleClosePreview}
                    dataEvent={selectedCompany}
                    pdfPreview={pdfPreview}
                    loading={loading}
                />
            )}

            <div className="w-full h-full sm:min-h-72 flex sm:flex-col gap-2 ">
                <div className="  bg-opacity-80 w-3/12 sm:w-full sm:min-h-72 h-full flex flex-col  bg-[#eeeeee] shadow-md z-10 pt-2 overflow-auto">
                    <div className="bg-opacity-80 w-full border-b flex justify-between px-5 border-main border-opacity-40 ">
                        <div>
                            <h1 className="text-4xl sm:text-2xl md:text-2xl pt-5 font-bold text-main">
                                General SnapIntel
                            </h1>
                            <h1 className="text-lg pt-3 pb-5 font-bold text-main">
                                {user.role === 'admin' ? 'Company List' : user.firstname}
                            </h1>
                        </div>
                    </div>
                    <div className="px-5 py-5">
                        <h5 className="text-lg pb-5 font-bold text-main">
                            {user.role === 'admin'
                                ? 'Companies with General SnapIntel'
                                : 'Company Users'}
                        </h5>
                        <div
                            className={`${
                                user.role === 'admin' ? 'gap-1' : 'gap-1'
                            } h-full w-full flex flex-col  overflow-y-auto`}
                        >
                            {user.role === 'admin'
                                ? companies?.result &&
                                  companies?.result.map((company, companyIndex) => (
                                      <span key={companyIndex}>
                                          <button
                                              onClick={() => {
                                                  handleCompany(company, companyIndex)
                                              }}
                                              to="/spaces_and_events"
                                              className="border-l-4 border-main transition-all duration-300 w-full bg-company text-sm hover:text-lg bg-no-repeat bg-right bg-contain bg-[#fefefe] h-[70px] rounded-lg shadow-md font-semibold text-grey hover:text-main text-left flex flex-col pl-5 justify-center items-start"
                                          >
                                              <span
                                                  className={`${
                                                      company?.firstname ===
                                                          selectedCompany?.firstname &&
                                                      'font-bold underline'
                                                  } `}
                                              >
                                                  {company.firstname}
                                              </span>
                                          </button>
                                          <div className="flex flex-col px-4">
                                              {selectedCompanyIndex === companyIndex &&
                                                  userContainer &&
                                                  userContainer.map((user) => {
                                                      const responses = user.responses || []
                                                      const firstResponse = responses[0] || {}
                                                      const nestedResponses =
                                                          firstResponse.responses || []
                                                      const latestResponse =
                                                          nestedResponses.length > 0
                                                              ? nestedResponses[
                                                                    nestedResponses.length - 1
                                                                ]
                                                              : firstResponse
                                                      const attachments =
                                                          latestResponse.attachments || []

                                                      return (
                                                          <button
                                                              key={user.id}
                                                              onClick={() => {
                                                                  setConvoContainer(user)
                                                                  setUserID(user.id)
                                                                  setCompanyID(user.company_id)
                                                                  setUserCon(user)
                                                              }}
                                                              className="flex items-left animate_fade_up w-full flex-col text-left bg-main text-sm text-white rounded-sm p-2 mt-2 hover:text-lg transition-all duration-300"
                                                          >
                                                              <p
                                                                  className={`${
                                                                      user?.fullname ===
                                                                          convoContainer?.fullname &&
                                                                      'font-bold underline text-black'
                                                                  } flex gap-2`}
                                                              >
                                                                  <IoMdPerson />
                                                                  {user.fullname}
                                                              </p>
                                                              <div className=" w-full rounded-md shadow-sm  text-white">
                                                                  {responses.length === 0 ? (
                                                                      <p>No responses available</p>
                                                                  ) : nestedResponses.length ===
                                                                    0 ? (
                                                                      <p>No responses available</p>
                                                                  ) : (
                                                                      <div>
                                                                          <p className="text-xs">
                                                                              Latest Response..
                                                                          </p>
                                                                          <p className="italic m-1 ml-3 line-clamp-2">
                                                                              {
                                                                                  latestResponse.answer
                                                                              }
                                                                          </p>
                                                                          <ul>
                                                                              {attachments
                                                                                  .slice(0, 1)
                                                                                  .map(
                                                                                      (
                                                                                          attachment,
                                                                                          index
                                                                                      ) => (
                                                                                          <li
                                                                                              key={
                                                                                                  index
                                                                                              }
                                                                                          >
                                                                                              {attachment.type ===
                                                                                              'image' ? (
                                                                                                  <NavLink
                                                                                                      to={
                                                                                                          attachment.filename
                                                                                                      }
                                                                                                      target="__blank"
                                                                                                      className="transition-all duration-300 flex gap-4 h-fit w-full items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                                                  >
                                                                                                      <img
                                                                                                          src={
                                                                                                              attachment.filename
                                                                                                          }
                                                                                                          alt="Attachment"
                                                                                                          className="h-[100px]"
                                                                                                          onClick={() =>
                                                                                                              setSelectedImage(
                                                                                                                  attachment.filename
                                                                                                              )
                                                                                                          }
                                                                                                      />
                                                                                                      <p className="text-left text-xs break-all truncate-multiline-2">
                                                                                                          {
                                                                                                              attachment.filename
                                                                                                          }
                                                                                                      </p>
                                                                                                  </NavLink>
                                                                                              ) : attachment.type ===
                                                                                                'file' ? (
                                                                                                  <NavLink
                                                                                                      to={
                                                                                                          attachment.filename
                                                                                                      }
                                                                                                      target="__blank"
                                                                                                      className="transition-all duration-300 flex gap-4 h-fit w-full items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                                                  >
                                                                                                      <img
                                                                                                          className="w-8"
                                                                                                          src={
                                                                                                              PDFIcon
                                                                                                          }
                                                                                                          alt="TEXT_FILE"
                                                                                                      />
                                                                                                      <p className="text-left text-xs break-all truncate-multiline-2">
                                                                                                          {
                                                                                                              attachment.filename
                                                                                                          }
                                                                                                      </p>
                                                                                                  </NavLink>
                                                                                              ) : null}
                                                                                          </li>
                                                                                      )
                                                                                  )}
                                                                          </ul>
                                                                          <p className="text-[9px] text-right">
                                                                              {
                                                                                  latestResponse.created_at
                                                                              }
                                                                          </p>
                                                                      </div>
                                                                  )}
                                                              </div>
                                                          </button>
                                                      )
                                                  })}
                                          </div>
                                      </span>
                                  ))
                                : companies?.result &&
                                  companies?.result.map((user, userIndex) => {
                                      const responses = user.responses || []
                                      const firstResponse = responses[0] || {}
                                      const nestedResponses = firstResponse.responses || []
                                      const latestResponse =
                                          nestedResponses.length > 0
                                              ? nestedResponses[nestedResponses.length - 1]
                                              : firstResponse
                                      const attachments = latestResponse.attachments || []

                                      return (
                                          <button
                                              key={user.id}
                                              onClick={() => {
                                                  setConvoContainer(user)
                                                  setUserID(user.id)
                                                  setCompanyID(user.company_id)
                                                  setUserCon(user)
                                              }}
                                              className="flex items-left animate_fade_up w-full flex-col text-left bg-main text-sm text-white rounded-sm p-2 mt-2 hover:text-lg transition-all duration-300"
                                          >
                                              <p
                                                  className={`${
                                                      user?.fullname === convoContainer?.fullname &&
                                                      'font-bold underline text-black'
                                                  } flex gap-2`}
                                              >
                                                  <IoMdPerson />
                                                  {user.fullname}
                                              </p>
                                              <div className=" w-full rounded-md shadow-sm  text-white">
                                                  {responses.length === 0 ? (
                                                      <p>No responses available</p>
                                                  ) : nestedResponses.length === 0 ? (
                                                      <p>No responses available</p>
                                                  ) : (
                                                      <div>
                                                          <p className="text-xs">
                                                              Latest Response..
                                                          </p>
                                                          <p className="italic m-1 ml-3 line-clamp-2">
                                                              {latestResponse.answer}
                                                          </p>
                                                          <ul>
                                                              {attachments
                                                                  .slice(0, 1)
                                                                  .map((attachment, index) => (
                                                                      <li key={index}>
                                                                          {attachment.type ===
                                                                          'image' ? (
                                                                              <NavLink
                                                                                  to={
                                                                                      attachment.filename
                                                                                  }
                                                                                  target="__blank"
                                                                                  className="transition-all duration-300 flex gap-4 h-fit w-full items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                              >
                                                                                  <img
                                                                                      src={
                                                                                          attachment.filename
                                                                                      }
                                                                                      alt="Attachment"
                                                                                      className="h-[100px]"
                                                                                      onClick={() =>
                                                                                          setSelectedImage(
                                                                                              attachment.filename
                                                                                          )
                                                                                      }
                                                                                  />
                                                                                  <p className="text-left text-xs break-all truncate-multiline-2">
                                                                                      {
                                                                                          attachment.filename
                                                                                      }
                                                                                  </p>
                                                                              </NavLink>
                                                                          ) : attachment.type ===
                                                                            'file' ? (
                                                                              <NavLink
                                                                                  to={
                                                                                      attachment.filename
                                                                                  }
                                                                                  target="__blank"
                                                                                  className="transition-all duration-300 flex gap-4 h-fit w-full items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                              >
                                                                                  <img
                                                                                      className="w-8"
                                                                                      src={PDFIcon}
                                                                                      alt="TEXT_FILE"
                                                                                  />
                                                                                  <p className="text-left text-xs break-all truncate-multiline-2">
                                                                                      {
                                                                                          attachment.filename
                                                                                      }
                                                                                  </p>
                                                                              </NavLink>
                                                                          ) : null}
                                                                      </li>
                                                                  ))}
                                                          </ul>
                                                          <p className="text-[9px] text-right">
                                                              {latestResponse.created_at}
                                                          </p>
                                                      </div>
                                                  )}
                                              </div>
                                          </button>
                                      )
                                  })}
                        </div>
                    </div>
                </div>

                <div className="md:w-full sm:w-full sm:h-full bg-opacity-80 w-9/12 h-full flex flex-col bg-[#e9f3e8] custom-scroll">
                    <div className="bg-opacity-80 w-full h-fit flex justify-between bg-main px-10 py-5 shadow-md z-20">
                        <div className="font-bold text-white">
                            <p className=" text-lg">{selectedCompany?.firstname}</p>
                            {convoContainer?.fullname === undefined ? (
                                <p className=" text-sm">Select a User</p>
                            ) : (
                                <Fragment>
                                    <p className="text-sm">User : {convoContainer?.fullname}</p>
                                </Fragment>
                            )}
                        </div>
                        <div className="w-1/7 text-base flex justify-evenly h-full items-center font-bold gap-5">
                            <button
                                disabled={userID == null || loading}
                                onClick={() =>
                                    handleDownloadDocx(
                                        selectedCompany?.id,

                                        convoContainer?.id
                                    )
                                }
                                className="cursor-pointer flex text-[#f7f7f7c0] justify-center flex-col items-center disabled:hidden hover:text-xl transition-all duration-300"
                            >
                                <FaFileDownload />
                                <p className="text-xs text-white">Docx</p>
                            </button>

                            {loading && <DownloadProgress progress={progress} />}

                            <button
                                disabled={userID == null || loading}
                                onClick={() =>
                                    handleGeneratePDF(
                                        selectedCompany?.id,

                                        convoContainer?.id
                                    )
                                }
                                className="cursor-pointer flex text-[#f7f7f7c0] justify-center flex-col items-center disabled:hidden hover:text-xl transition-all duration-300"
                            >
                                <FaFileDownload />
                                <p className="text-xs text-white">PDF</p>
                            </button>
                            <button
                                disabled={userID == null}
                                onClick={() => exportCSV(userID, companyID)}
                                className=" cursor-pointer  flex text-[#f7f7f7c0] justify-center flex-col items-center disabled:hidden hover:text-xl transition-all duration-300"
                            >
                                <FaFileDownload />
                                <p className="text-xs text-white">
                                    {/* <span className="sm:hidden">Download</span>  */}
                                    CSV
                                </p>
                            </button>
                        </div>
                    </div>
                    {convoContainer == null ? (
                        <div className="w-full h-full flex justify-center mt-2 items-center flex-col font-bold text-main opacity-70">
                            <span className="text-8xl">
                                <BsPersonSquare />
                            </span>
                            <p className="text-2xl">Select a User</p>
                        </div>
                    ) : (
                        <div className="h-full w-full p-4 flex flex-col gap-2 overflow-y-auto overflow-x-hidden">
                            {console.log('dasj', convoContainer)}
                            {convoContainer?.responses && convoContainer?.responses.length > 0 ? (
                                convoContainer?.responses &&
                                convoContainer?.responses.map((convo, index) => (
                                    <Fragment>
                                        <div
                                            onClick={() => handleQuestion(index)}
                                            className="w-full cursor-pointer hover:scale-105 transition-all duration-150 font-semibold text-gray-600"
                                        >
                                            <Divider>{convo?.date}</Divider>
                                        </div>

                                        {questionToggles[index] &&
                                            convo?.responses &&
                                            convo?.responses
                                                // .slice(0, 7)
                                                .map((question, questionIndex) => (
                                                    <Fragment>
                                                        <div>
                                                            <button className="transition-all duration-300 px-4 py-2 w-3/4 bg-main text-white text-sm text-left rounded-md shadow-md hover:translate-x-2 ">
                                                                {question?.question?.question}
                                                            </button>
                                                            <div className="w-full flex flex-col justify-end items-end my-5">
                                                                <p className="animate_fade_up max-w-[75%] w-fit self-end break-words text-sm bg-white px-4 py-2 shadow-sm rounded-sm bg-opacity-70">
                                                                    <p
                                                                        style={{
                                                                            whiteSpace: 'pre-line',
                                                                        }}
                                                                    >
                                                                        {question.answer &&
                                                                            makeUrlsClickable(
                                                                                question.answer
                                                                            ).map((part, index) => (
                                                                                <React.Fragment
                                                                                    key={index}
                                                                                >
                                                                                    {part}
                                                                                </React.Fragment>
                                                                            ))}
                                                                    </p>

                                                                    {question?.attachments &&
                                                                        question?.attachments
                                                                            .length > 0 && (
                                                                            <React.Fragment>
                                                                                {question?.attachments.filter(
                                                                                    (attachment) =>
                                                                                        attachment.type ===
                                                                                        'image'
                                                                                ).length > 1 ? (
                                                                                    <div className="grid grid-cols-4 gap-1">
                                                                                        {question?.attachments
                                                                                            .filter(
                                                                                                (
                                                                                                    attachment
                                                                                                ) =>
                                                                                                    attachment.type ===
                                                                                                    'image'
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    attachment
                                                                                                ) => (
                                                                                                    <div
                                                                                                        className="flex items-start justify-start w-full"
                                                                                                        key={
                                                                                                            attachment.id
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            className="transition-all duration-300 w-[200px] h-[100px] cursor-pointer hover:brightness-50"
                                                                                                            src={
                                                                                                                attachment.filename
                                                                                                            }
                                                                                                            alt="Attachment"
                                                                                                            onClick={() =>
                                                                                                                setSelectedImage(
                                                                                                                    attachment.filename
                                                                                                                )
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                    </div>
                                                                                ) : (
                                                                                    question?.attachments
                                                                                        .filter(
                                                                                            (
                                                                                                attachment
                                                                                            ) =>
                                                                                                attachment.type ===
                                                                                                'image'
                                                                                        )
                                                                                        .map(
                                                                                            (
                                                                                                attachment
                                                                                            ) => (
                                                                                                <div
                                                                                                    className="flex items-start justify-start w-full"
                                                                                                    key={
                                                                                                        attachment.id
                                                                                                    }
                                                                                                >
                                                                                                    <img
                                                                                                        className="transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                                                                        src={
                                                                                                            attachment.filename
                                                                                                        }
                                                                                                        alt="Attachment"
                                                                                                        onClick={() =>
                                                                                                            setSelectedImage(
                                                                                                                attachment.filename
                                                                                                            )
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        )
                                                                                )}
                                                                                <div>
                                                                                    {question?.attachments
                                                                                        .filter(
                                                                                            (
                                                                                                attachment
                                                                                            ) =>
                                                                                                attachment.type !==
                                                                                                'image'
                                                                                        )
                                                                                        .map(
                                                                                            (
                                                                                                attachment
                                                                                            ) => (
                                                                                                <div
                                                                                                    className="flex items-start justify-start w-full"
                                                                                                    key={
                                                                                                        attachment.id
                                                                                                    }
                                                                                                >
                                                                                                    {attachment.type ===
                                                                                                    'file' ? (
                                                                                                        <NavLink
                                                                                                            to={
                                                                                                                attachment.filename
                                                                                                            }
                                                                                                            target="__blank"
                                                                                                            className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                                                        >
                                                                                                            <img
                                                                                                                className="w-8"
                                                                                                                src={
                                                                                                                    PDFIcon
                                                                                                                }
                                                                                                                alt="TEXT_FILE"
                                                                                                            />
                                                                                                            <p className="text-left text-xs break-all truncate-multiline-2">
                                                                                                                {
                                                                                                                    attachment.filename
                                                                                                                }
                                                                                                            </p>
                                                                                                        </NavLink>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                            )
                                                                                        )}
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                ))}
                                    </Fragment>
                                ))
                            ) : (
                                <div className="w-full h-full flex flex-col justify-center items-center animate_zoom_in">
                                    <p className="text-4xl text-main">
                                        <RiMessageFill />
                                    </p>
                                    <p className="text-2xl text-gray-600">No Record</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default GeneralSnapIntel
