import React from 'react'

import MainNav from 'components/MainNav'

import { UserResponse } from './GenSnapIntelModal'
import GeneralSnapIntel from './GeneralSnapIntel'

export default function GenSnapIntel() {
    return (
        <div className="flex flex-col w-screen h-screen overflow-hidden">
            <div className="opacity-50 fixed w-full h-full bg-dashboard bg-[length:600px_300px] bg-center -z-10 brightness-[.8]"></div>
            <div className="w-full h-full flex flex-col gap-5 py-5 mb-10">
                <div className="w-full h-3/12 px-5">
                    <MainNav />
                </div>

                <div className="w-full h-full overflow-y-auto rounded-md px-5 custom-scroll flex flex-col  grow">
                    <GeneralSnapIntel />
                </div>
            </div>
        </div>
    )
}
