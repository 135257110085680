import React from 'react'
import { MdOutlineFileDownload } from 'react-icons/md'

const PdfDownload = ({handleDownloadPDF, dataEvent}) => {
  return (
    <div className="animate_fade_up z-50 mt-[7.5rem] -ml-14 absolute transition-all duration-300 cursor-pointer hover:bg-opacity-100 flex flex-col text-left  rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main">
    <button
        className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
        onClick={() => {
            handleDownloadPDF(
                dataEvent?.id,
                ['General Discussions'],
                'desc'
            )
        }}
    >
        General Discussions PDF{' '}
        <span className="text-lg">
            <MdOutlineFileDownload />
        </span>
    </button>
    <button
        className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
        onClick={() => {
            handleDownloadPDF(
                dataEvent?.id,
                ['Chatroom KIQs'],
                'desc'
            )
        }}
    >
        Chatroom PDF{' '}
        <span className="text-lg">
            <MdOutlineFileDownload />
        </span>
    </button>

    <button
        className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
        onClick={() => {
            handleDownloadPDF(
                dataEvent?.id,
                ['My KIQs'],
                'desc'
            )
        }}
    >
        My KIQs PDF{' '}
        <span className="text-lg">
            <MdOutlineFileDownload />
        </span>
    </button>
    <button
        className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
        onClick={() => {
            handleDownloadPDF(
                dataEvent?.id,
                ['SnapIntel'],
                'desc'
            )
        }}
    >
        SnapIntel PDF{' '}
        <span className="text-lg">
            <MdOutlineFileDownload />
        </span>
    </button>
    <button
        className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
        onClick={() =>
            handleDownloadPDF(
                dataEvent?.id,
                [
                    'General Discussions',
                    'Chatroom KIQs',
                    'My KIQs',
                    'SnapIntel',
                ],
                'desc'
            )
        }
    >
        Event PDF{' '}
        <span className="text-lg">
            <MdOutlineFileDownload />
        </span>
    </button>
</div>  )
}

export default PdfDownload