import ImageView from 'components/ImageView'
import PDFIcon from '../../assets/pdf.png'
import React, { useEffect, useState } from 'react'
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { Link, NavLink } from 'react-router-dom'
import { FaFileDownload } from 'react-icons/fa'
import { PiSquaresFour } from 'react-icons/pi'
import { RiListCheck2 } from 'react-icons/ri'

// export const AnalyticsCompanyDetails = ({ data, onClose }) => {
//     useEffect(() => {
//         const handleKeyPress = (event) => {
//             if (event.key === 'Escape') {
//                 onClose()
//             }
//         }

//         // Attach the event listener when the component mounts
//         window.addEventListener('keydown', handleKeyPress)

//         // Detach the event listener when the component unmounts
//         return () => {
//             window.removeEventListener('keydown', handleKeyPress)
//         }
//     }, [onClose])

//     const [selectedImage, setSelectedImage] = useState()

//     const [dataContainer, setDataContainer] = useState(data ? data[0] : null)
//     return (
//         <div className="w-screen h-screen fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-25 backdrop-blur-sm z-50 flex justify-center items-center animate_zoom_in">
//             <div className="fixed bottom-10 right-10 ">
//                 <p className="text-sm w-fit whitespace-normal flex justify-center items-center text-darkgrey">
//                     Note: Click{' '}
//                     <span className="mx-2">
//                         <IoCloseCircleOutline />
//                     </span>{' '}
//                     or [ESC] key to close.
//                 </p>
//             </div>
//             {selectedImage != null && (
//                 <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
//             )}
//             <div className="w-3/4 bg-white rounded-md h-3/4 sm:h-5/6">
//                 <div className="w-full p-4 h-1/6 sm:h-[13%] text-main flex font-bold justify-between items-center sm:border-b sm:border-main sm:mb-3">
//                     <div>
//                         <p className="text-sm">Expanded List</p>
//                         <p className="text-lg">Company Events</p>
//                     </div>
//                     <span className="text-xl cursor-pointer" onClick={onClose}>
//                         <IoCloseCircleOutline />
//                     </span>
//                 </div>
//                 <div className="w-full h-5/6 sm:flex-col  flex gap-2 px-4 pb-5">
//                     <div className="w-1/2 sm:w-full  sm:inset-x-0  overflow-y-auto sm:overflow-y-hidden pr-2 gap-2 flex flex-col sm:flex-row sm:pb-1 sm:-mb-5">
//                         {data &&
//                             data.map((item) => (
//                                 <div
//                                     onClick={() => setDataContainer(item)}
//                                     className="transition-all duration-200 w-full sm:h-full shadow-sm px-5 sm:px-8 py-6 sm:py-1 border rounded-md flex justify-between items-center cursor-pointer hover:text-[#eee] hover:shadow-md sm:gap-1 sm:flex-col sm:pb-2 sm:pt-2"
//                                 >
//                                     <div className="h-full sm:justify-center sm:items-center sm:flex sm:flex-col ">
//                                         <p className="text-main font-bold sm:whitespace-normal leading-4 sm:text-center">
//                                             {item.firstname}
//                                         </p>
//                                     </div>
//                                     <span className=" text-center w-6 h-6 pt-1 sm:pb-3 text-main text-[.6em] font-bold aspect-square border-main border rounded-full">
//                                         {item.events_count}
//                                     </span>
//                                 </div>
//                             ))}
//                     </div>
//                     {dataContainer && (
//                         <div className=" w-full h-full sm:h-[75%] shadow-sm border rounded-md sm:self-end sm:mt-7">
//                             <div className="h-1/6 px-4 pt-4 pb-2 border-grey border-b w-full border-opacity-50">
//                                 <p className="text-main font-bold">{dataContainer?.firstname}</p>
//                                 <p className="text-grey font-semibold text-sm -mt-2">
//                                     {dataContainer?.domain_name}
//                                 </p>
//                             </div>
//                             <div className="w-full h-5/6 overflow-y-auto gap-y-2 flex flex-col p-5 leading-5">
//                                 {dataContainer?.events &&
//                                     dataContainer?.events.map((item) => (
//                                         <div className=" transition-all duration-200 w-full shadow-sm gap-4 leading-5 px-5 py-6 border rounded-md flex justify-between items-center cursor-default">
//                                             <div className="w-1/3 h-full">
//                                                 <p className="text-main font-bold">
//                                                     {item.event_name}
//                                                 </p>
//                                                 <span className=" text-darkgrey text-[.8em] font-bold ">
//                                                     {item.created_at}
//                                                 </span>
//                                             </div>
//                                             <div className="w-2/3 text-[.6em] leading-4 justify-content">
//                                                 {item.description}
//                                             </div>
//                                         </div>
//                                     ))}
//                             </div>
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </div>
//     )
// }

export const AnalyticsDetails = ({ data, onClose }) => {
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose()
            }
        }

        window.addEventListener('keydown', handleKeyPress)

        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [onClose])

    const [selectedImage, setSelectedImage] = useState()
    const [kiqsType, setKiqsType] = useState(0)
    const [itemHolder, setItemHolder] = useState(data[0] ?? [])

    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }

    return (
        <div className="w-screen h-screen fixed top-0 bottom-0 left-0 pt-10 right-0 bg-black bg-opacity-25 backdrop-blur-sm z-30 flex justify-center items-center animate_zoom_in">
            <div className="fixed bottom-10 right-10 ">
                <p className="text-sm w-fit whitespace-normal flex justify-center items-center text-darkgrey">
                    Note: Click{' '}
                    <span className="mx-2">
                        <IoCloseCircleOutline />
                    </span>{' '}
                    or [ESC] key to close.
                </p>
            </div>
            {selectedImage != null && (
                <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
            )}

            <div className="w-[100%] bg-white rounded-md h-[96%] sm:h-5/6">
                <div className="w-full h-fit flex justify-between pt-4 px-10">
                    <p className="text-main font-semibold text-lg italic">
                        Users with Most Response
                    </p>
                    <span className="text-xl cursor-pointer" onClick={onClose}>
                        <IoCloseCircleOutline />
                    </span>
                </div>
                <div className="w-full flex grow h-5/6 flex-col">
                    <div className="w-full h-full flex">
                        <div className="h-full overflow-y-auto overflow-x-hidden  w-fit max-w-[30%]  px-5 py-2 flex flex-col gap-2 ">
                            {data &&
                                data.map((item, itemIndex) => (
                                    <div
                                        onClick={() => setItemHolder(item)}
                                        className="animate_fade_up text-sm flex items-center justify-between shadow-md rounded-md w-[15rem] py-2 px-2 bg-[#e7e7e7] hover:bg-[#cfcfcf] cursor-pointer"
                                    >
                                        <span className="w-2/3 overflow-x-auto">
                                            <p
                                                className={`${
                                                    item?.fullname === itemHolder?.fullname &&
                                                    'font-semibold underline'
                                                } text-sm`}
                                            >
                                                {item.fullname}
                                            </p>
                                            <p className="text-xs font-semibold text-main">
                                                {item.companybelong.firstname}
                                            </p>
                                        </span>
                                        <span className="w-fit px-5 flex justify-end border-l border-main ">
                                            <p>{item?.responses_count}</p>
                                        </span>
                                    </div>
                                ))}
                        </div>
                        <div className=" w-auto max-w-[78%] grow flex flex-col overflow-y-auto">
                            <div className="flex flex-col gap-2 text-sm px-5 sticky top-0 w-full bg-white shadow-sm ">
                                <p className="text-main font-semibold text-lg italic">
                                    {itemHolder.fullname} -{' '}
                                    {kiqsType == 0
                                        ? 'Chatroom KIQs'
                                        : kiqsType == 1
                                        ? 'My KIQs'
                                        : 'SnapIntel KIQs'}
                                </p>
                                <div className="gap-x-2 flex pb-2">
                                    <button
                                        onClick={() => setKiqsType(0)}
                                        className={`rounded-md shadow-sm py-2 px-4 font-semibold text-white bg-main ${
                                            kiqsType === 0 ? 'underline font-bold' : ''
                                        }`}
                                    >
                                        Chatroom{' '}
                                        <span className="text-xs">
                                            {itemHolder?.chatroom_responses.length}
                                        </span>
                                    </button>
                                    <button
                                        onClick={() => setKiqsType(1)}
                                        className={`rounded-md shadow-sm py-2 px-4 font-semibold text-white bg-[#333333] ${
                                            kiqsType === 1 ? 'underline font-bold' : ''
                                        }`}
                                    >
                                        My KIQs{' '}
                                        <span className="text-xs">
                                            {itemHolder?.mykiqs_responses.length}
                                        </span>
                                    </button>
                                    <button
                                        onClick={() => setKiqsType(2)}
                                        className={`rounded-md shadow-sm py-2 px-4 font-semibold text-main bg-main bg-opacity-25 ${
                                            kiqsType === 2 ? 'underline font-bold' : ''
                                        }`}
                                    >
                                        SnapIntel{' '}
                                        <span className="text-xs">
                                            {itemHolder?.snapintel_responses.length}
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="w-full px-5 py-5 flex flex-col gap-2 h-full">
                                {kiqsType == 0 &&
                                    itemHolder?.chatroom_responses &&
                                    itemHolder?.chatroom_responses.map((convo, convoIndex) => (
                                        <div className="animate_fade_up w-full text-xs rounded-md bg-gray-200 shadow-sm px-5 py-3 break-words">
                                            {/* {convo.answer} */}
                                            <p
                                                className="text-sm text-justify break-words"
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                }}
                                            >
                                                {convo.answer &&
                                                    makeUrlsClickable(convo.answer).map(
                                                        (part, index) => (
                                                            <React.Fragment key={index}>
                                                                {part}
                                                            </React.Fragment>
                                                        )
                                                    )}
                                            </p>
                                            <div className="w-full">
                                                {convo.attachments && (
                                                    <div className="flex flex-wrap">
                                                        {convo.attachments.map((attach) => {
                                                            if (attach.type === 'image') {
                                                                return (
                                                                    <img
                                                                        key={attach.id} // Add a key prop for React
                                                                        className="h-28 aspect-auto object-cover flex w-28 hover:object-contain m-1" // Added margin for spacing
                                                                        src={attach.filename}
                                                                        alt={attach.filename}
                                                                    />
                                                                )
                                                            } else {
                                                                return (
                                                                    <div
                                                                        className="h-28 w-full py-5 flex text-xs"
                                                                        key={attach.id}
                                                                    >
                                                                        <img
                                                                            alt="PDF Icon"
                                                                            className="h-full w-fit"
                                                                            src={PDFIcon}
                                                                        />
                                                                        <div className="w-full">
                                                                            <p className="font-bold text-darkgrey">
                                                                                Filename:
                                                                            </p>
                                                                            <Link
                                                                                className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                to={attach.filename}
                                                                                target="_blank"
                                                                            >
                                                                                {attach.filename}
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                {kiqsType == 1 &&
                                    itemHolder?.mykiqs_responses &&
                                    itemHolder?.mykiqs_responses.map((convo, convoIndex) => (
                                        <div
                                            key={convoIndex}
                                            className="animate_fade_up w-full text-xs rounded-md bg-gray-200 shadow-sm px-5 py-3 break-words"
                                        >
                                            {/* {convo.answer} */}
                                            <p
                                                className="text-sm text-justify break-words"
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                }}
                                            >
                                                {convo.answer &&
                                                    makeUrlsClickable(convo.answer).map(
                                                        (part, index) => (
                                                            <React.Fragment key={index}>
                                                                {part}
                                                            </React.Fragment>
                                                        )
                                                    )}
                                            </p>
                                            <div className="w-full">
                                                {convo.attachments && (
                                                    <div className="flex flex-wrap">
                                                        {convo.attachments.map((attach) => {
                                                            if (attach.type === 'image') {
                                                                return (
                                                                    <img
                                                                        key={attach.id} // Add a key prop for React
                                                                        className="h-28 aspect-auto object-cover flex w-28 hover:object-contain m-1" // Added margin for spacing
                                                                        src={attach.filename}
                                                                        alt={attach.filename}
                                                                    />
                                                                )
                                                            } else {
                                                                return (
                                                                    <div
                                                                        className="h-28 w-full py-5 flex text-xs"
                                                                        key={attach.id}
                                                                    >
                                                                        <img
                                                                            alt="PDF Icon"
                                                                            className="h-full w-fit"
                                                                            src={PDFIcon}
                                                                        />
                                                                        <div className="w-full">
                                                                            <p className="font-bold text-darkgrey">
                                                                                Filename:
                                                                            </p>
                                                                            <Link
                                                                                className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                to={attach.filename}
                                                                                target="_blank"
                                                                            >
                                                                                {attach.filename}
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                {kiqsType == 2 &&
                                    itemHolder?.snapintel_responses &&
                                    itemHolder?.snapintel_responses.map((convo, convoIndex) => (
                                        <div
                                            key={convoIndex}
                                            className="animate_fade_up w-full text-xs rounded-md bg-gray-200 shadow-sm px-5 py-3 break-words"
                                        >
                                            {/* {convo.answer} */}
                                            <p
                                                className="text-sm text-justify break-words"
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                }}
                                            >
                                                {convo.answer &&
                                                    makeUrlsClickable(convo.answer).map(
                                                        (part, index) => (
                                                            <React.Fragment key={index}>
                                                                {part}
                                                            </React.Fragment>
                                                        )
                                                    )}
                                            </p>
                                            <div className="w-full">
                                                {convo.attachments && (
                                                    <div className="flex flex-wrap">
                                                        {convo.attachments.map((attach) => {
                                                            if (attach.type === 'image') {
                                                                return (
                                                                    <img
                                                                        key={attach.id} // Add a key prop for React
                                                                        className="h-28 aspect-auto object-cover flex w-28 hover:object-contain m-1" // Added margin for spacing
                                                                        src={attach.filename}
                                                                        alt={attach.filename}
                                                                    />
                                                                )
                                                            } else {
                                                                return (
                                                                    <div
                                                                        className="h-28 w-full py-5 flex text-xs"
                                                                        key={attach.id}
                                                                    >
                                                                        <img
                                                                            alt="PDF Icon"
                                                                            className="h-full w-fit"
                                                                            src={PDFIcon}
                                                                        />
                                                                        <div className="w-full">
                                                                            <p className="font-bold text-darkgrey">
                                                                                Filename:
                                                                            </p>
                                                                            <Link
                                                                                className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                to={attach.filename}
                                                                                target="_blank"
                                                                            >
                                                                                {attach.filename}
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const AnalyticsEventsDetails = ({ data, onClose }) => {
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose()
            }
        }

        // Attach the event listener when the component mounts
        window.addEventListener('keydown', handleKeyPress)

        // Detach the event listener when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [onClose])
    const [selectedImage, setSelectedImage] = useState()

    const [dataContainer, setDataContainer] = useState(data)
    const [itemHolder, setItemHolder] = useState([])
    const [selectedItem, setSelectedItem] = useState()
    const [kiqsType, setKiqsType] = useState(0)

    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }
    return (
        <div className="w-screen h-screen fixed pt-10 top-0 bottom-0 left-0 right-0 bg-black bg-opacity-25 backdrop-blur-sm z-30 flex justify-center items-center animate_zoom_in">
            <div className="fixed bottom-10 right-10 ">
                <p className="text-sm w-fit whitespace-normal flex justify-center items-center text-darkgrey">
                    Note: Click{' '}
                    <span className="mx-2">
                        <IoCloseCircleOutline />
                    </span>{' '}
                    or [ESC] key to close.
                </p>
            </div>
            {selectedImage != null && (
                <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
            )}
            <div className="w-[100%] bg-white rounded-md h-[96%] sm:h-6/6">
                <div className="flex gap-2 text-sm px-5 sticky top-0 w-full bg-white shadow-sm rounded-md py-2 h-1/7 justify-between">
                    <div className="flex h-full items-center w-full ">
                        <p className="flex flex-col text-main font-semibold text-lg italic">
                            <span>Questions with Most Responses </span>
                            <div className="w-full flex  gap-36 sm:gap-0 sm:flex-col">
                                <span className="text-darkgrey w-52">
                                    {kiqsType == 0
                                        ? 'Chatroom KIQs'
                                        : kiqsType == 1
                                        ? 'My KIQs'
                                        : 'SnapIntel KIQs'}
                                </span>
                                <div className="gap-x-2 flex w-full h-9 justify-start">
                                    <button
                                        onClick={() => {
                                            setKiqsType(0)
                                            setSelectedItem(null)
                                            setItemHolder([])
                                        }}
                                        // className=" rounded-md shadow-sm py-2 px-4 font-semibold text-white bg-main"
                                        className={`rounded-md shadow-sm px-4 font-semibold text-white bg-main ${
                                            kiqsType === 0 ? 'underline font-bold' : ''
                                        }`}
                                    >
                                        Chatroom{' '}
                                        <span className="text-xs">
                                            {dataContainer?.chatroom?.questions.length}
                                        </span>
                                    </button>
                                    <button
                                        onClick={() => {
                                            setKiqsType(1)
                                            setSelectedItem(null)
                                            setItemHolder([])
                                        }}
                                        // className=" rounded-md shadow-sm py-2 px-4 font-semibold text-white bg-[#333333]"
                                        className={`rounded-md shadow-sm  px-4 font-semibold text-white bg-[#333333] ${
                                            kiqsType === 1 ? 'underline font-bold' : ''
                                        }`}
                                    >
                                        My KIQs{' '}
                                        <span className="text-xs">
                                            {dataContainer?.mykiqs?.questions?.length}
                                        </span>
                                    </button>
                                    <button
                                        onClick={() => {
                                            setKiqsType(2)
                                            setSelectedItem(null)
                                            setItemHolder([])
                                        }}
                                        // className=" rounded-md shadow-sm py-2 px-4 font-semibold text-main bg-main bg-opacity-25"
                                        className={`rounded-md shadow-sm px-4 font-semibold text-main bg-main bg-opacity-25 ${
                                            kiqsType === 2 ? 'underline font-bold' : ''
                                        }`}
                                    >
                                        SnapIntel{' '}
                                        <span className="text-xs">
                                            {dataContainer?.snapintel.questions?.length}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </p>
                    </div>

                    <span className="pt-2 text-xl cursor-pointer self-start" onClick={onClose}>
                        <IoCloseCircleOutline />
                    </span>
                </div>
                <div className="w-full flex h-[75%] ">
                    <div className="h-full overflow-y-auto overflow-x-hidden sm:overflow-x-auto border-r border-main border-opacity-30 bg-opacity-60 bg-gray-200 w-fit max-w-[30%]  px-5 py-2 flex flex-col gap-2 ">
                        {kiqsType == 0 &&
                            dataContainer?.chatroom?.questions &&
                            dataContainer?.chatroom?.questions.map((chat_questions, index) => (
                                <div
                                    onClick={() => {
                                        setItemHolder(chat_questions.responses)
                                        setSelectedItem(index)
                                    }}
                                    className={`hover:scale-105 transition-all duration-150 break-normal text-sm flex items-center justify-between shadow-md rounded-md w-[15rem] py-2 px-2 cursor-pointer ${
                                        selectedItem == index && 'bg-main text-white font-semibold'
                                    }`}
                                >
                                    {chat_questions.question}
                                </div>
                            ))}
                        {kiqsType == 1 &&
                            dataContainer?.mykiqs?.questions &&
                            dataContainer?.mykiqs?.questions.map((chat_questions, index) => (
                                <div
                                    onClick={() => {
                                        setItemHolder(chat_questions.responses)
                                        setSelectedItem(index)
                                    }}
                                    className={`hover:scale-105 transition-all duration-150 break-normal text-sm flex items-center justify-between shadow-md rounded-md w-[15rem] py-2 px-2 cursor-pointer ${
                                        selectedItem == index && 'bg-main text-white font-semibold'
                                    }`}
                                >
                                    {chat_questions.question}
                                </div>
                            ))}
                        {kiqsType == 2 &&
                            dataContainer?.snapintel?.questions &&
                            dataContainer?.snapintel?.questions
                                .slice(0, 7)
                                .map((chat_questions, index) => (
                                    <div
                                        onClick={() => {
                                            setItemHolder(chat_questions.responses)
                                            setSelectedItem(index)
                                        }}
                                        className={`hover:scale-105 transition-all duration-150 break-normal text-sm flex items-center justify-between shadow-md rounded-md w-[15rem] py-2 px-2 cursor-pointer ${
                                            selectedItem == index &&
                                            'bg-main text-white font-semibold'
                                        }`}
                                    >
                                        {chat_questions.question}
                                    </div>
                                ))}
                    </div>
                    <div className="w-2/3 flex flex-col grow  overflow-y-auto overflow-x-hidden h-full px-5 gap-2 py-2">
                        {itemHolder.length > 0 ? (
                            itemHolder &&
                            itemHolder.map((convo, convoIndex) => (
                                <div
                                    key={convoIndex}
                                    className="w-full text-xs rounded-md bg-gray-200 px-5 py-3 break-word shadow-sm animate_fade_up"
                                >
                                    {/* {convo.answer} */}
                                    {/* <p dangerouslySetInnerHTML={makeUrlsClickable(convo.answer)} /> */}
                                    <p
                                        className="text-sm text-justify break-words"
                                        style={{
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        {convo.answer &&
                                            makeUrlsClickable(convo.answer).map((part, index) => (
                                                <React.Fragment key={index}>{part}</React.Fragment>
                                            ))}
                                    </p>
                                    <div className="w-full">
                                        {convo.attachments && (
                                            <div className="flex flex-wrap">
                                                {convo.attachments.map((attach) => {
                                                    if (attach.type === 'image') {
                                                        return (
                                                            <img
                                                                key={attach.id} // Add a key prop for React
                                                                className="h-28 aspect-auto object-cover flex w-28 hover:object-contain m-1" // Added margin for spacing
                                                                src={attach.filename}
                                                                alt={attach.filename}
                                                            />
                                                        )
                                                    } else {
                                                        return (
                                                            <div
                                                                className="h-28 w-full py-5 flex text-xs"
                                                                key={attach.id}
                                                            >
                                                                <img
                                                                    alt="PDF Icon"
                                                                    className="h-full w-fit"
                                                                    src={PDFIcon}
                                                                />
                                                                <div className="w-full">
                                                                    <p className="font-bold text-darkgrey">
                                                                        Filename:
                                                                    </p>
                                                                    <Link
                                                                        className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                        to={attach.filename}
                                                                        target="_blank"
                                                                    >
                                                                        {attach.filename}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="w-full flex flex-col justify-center items-center h-full">
                                <p>There is no record in this section</p>
                                <p className="text-sm font-semibold text-main">Select a Question</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const AnalyticsReactionsDetails = ({ data, onClose }) => {
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose()
            }
        }

        // Attach the event listener when the component mounts
        window.addEventListener('keydown', handleKeyPress)

        // Detach the event listener when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [onClose])
    const [selectedImage, setSelectedImage] = useState()

    const [dataContainer, setDataContainer] = useState(data ? data[0] : null)
    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }
    return (
        <div className="w-screen h-screen fixed pt-10 top-0 bottom-0 left-0 right-0 bg-black bg-opacity-25 backdrop-blur-sm z-30 flex justify-center items-center animate_zoom_in">
            <div className="fixed bottom-10 right-10 ">
                <p className="text-sm w-fit whitespace-normal flex justify-center items-center text-darkgrey">
                    Note: Click{' '}
                    <span className="mx-2">
                        <IoCloseCircleOutline />
                    </span>{' '}
                    or [ESC] key to close.
                </p>
            </div>
            {selectedImage != null && (
                <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
            )}
            <div className="w-[100%] bg-white rounded-md h-[96%] md:overflow-y-auto sm:overflow-y-auto md:pb-10 sm:pb-10">
                <div className="w-full p-4 h-1/6 text-main flex font-bold justify-between items-center">
                    <span className="-mt-2">
                        <p className="text-sm">Expanded List</p>
                        <p className="text-lg">Most Liked and Disliked Responses</p>
                    </span>
                    <span className="text-xl mr-4 -mt-8 cursor-pointer" onClick={onClose}>
                        <IoCloseCircleOutline />
                    </span>
                </div>
                <div className="w-full h-5/6 -mt-6 flex gap-2 px-4 pb-5 md:flex-col sm:flex-col md:pb-10 sm:pb-10">
                    <div className="w-1/2 h-full rounded-md border border-opacity-50 md:w-full sm:w-full">
                        <div className="w-full h-[10%] flex text-center font-bold items-center justify-center text-main border-b border-opacity-50">
                            {' '}
                            <span className="-scale-x-100 ">
                                <BiSolidLike />{' '}
                            </span>
                            Most Likes
                        </div>
                        <div className="w-full h-[90%] overflow-y-auto p-5 flex flex-col gap-4">
                            {data?.most_liked &&
                                data?.most_liked.map((like) => (
                                    <div
                                        className="flex flex-col w-full items-end justify-end"
                                        key={like.id}
                                    >
                                        <span className="text-xs font-bold mb-2">
                                            {like?.user?.fullname}
                                        </span>
                                        <span className="text-xs gap-2 flex flex-col w-fit max-w-[90%] justify-start items-end text-left bg-[#e1e1e1] bg-opacity-30 py-2 pl-2 pr-4 rounded-l-md rounded-tr-md shadow-sm">
                                            {/* {like.answer} */}
                                            <p
                                                className="text-sm text-justify break-words"
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                }}
                                            >
                                                {like.answer &&
                                                    makeUrlsClickable(like.answer).map(
                                                        (part, index) => (
                                                            <React.Fragment key={index}>
                                                                {part}
                                                            </React.Fragment>
                                                        )
                                                    )}
                                            </p>
                                            {like.attachments && like.attachments.length > 0 && (
                                                <React.Fragment>
                                                    {like.attachments && (
                                                        <div className="flex flex-wrap">
                                                            {like.attachments.map((attach) => {
                                                                if (attach.type === 'image') {
                                                                    return (
                                                                        <img
                                                                            key={attach.id} // Add a key prop for React
                                                                            className="h-28 aspect-auto object-cover flex w-28 hover:object-contain m-1" // Added margin for spacing
                                                                            src={attach.filename}
                                                                            alt={attach.filename}
                                                                        />
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <div
                                                                            className="h-28 w-full py-5 flex text-xs"
                                                                            key={attach.id}
                                                                        >
                                                                            <img
                                                                                alt="PDF Icon"
                                                                                className="h-full w-fit"
                                                                                src={PDFIcon}
                                                                            />
                                                                            <div className="w-full">
                                                                                <p className="font-bold text-darkgrey">
                                                                                    Filename:
                                                                                </p>
                                                                                <Link
                                                                                    className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                    to={
                                                                                        attach.filename
                                                                                    }
                                                                                    target="_blank"
                                                                                >
                                                                                    {
                                                                                        attach.filename
                                                                                    }
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            })}
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            <div className="w-full flex gap-4 justify-between">
                                                {like.type === 0 && (
                                                    <div className="flex gap-2">
                                                        <div className="flex">
                                                            <span className="text-main">
                                                                <BiSolidLike />
                                                            </span>
                                                            <span>{like.likes_count}</span>
                                                        </div>
                                                        <div className="flex">
                                                            <span className="text-grey">
                                                                <BiSolidDislike />
                                                            </span>
                                                            <span>{like.dislikes_count}</span>
                                                        </div>
                                                    </div>
                                                )}
                                                <div>
                                                    <span>{like.created_at}</span>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="w-1/2 h-full rounded-md border border-opacity-50 md:w-full sm:w-full">
                        <div className="w-full h-[10%] flex text-center font-bold items-center justify-center text-darkgrey border-b border-opacity-50">
                            {' '}
                            <span className="-scale-x-100 ">
                                <BiSolidDislike />{' '}
                            </span>
                            Most Dislikes
                        </div>
                        <div className="w-full h-[90%] overflow-y-auto p-5 flex flex-col gap-4">
                            {data?.most_disliked &&
                                data?.most_disliked.map((dislike) => (
                                    <div
                                        className="flex flex-col w-full items-end justify-end"
                                        key={dislike.id}
                                    >
                                        <span className="text-xs font-bold mb-2">
                                            {dislike?.user?.fullname}
                                        </span>
                                        <span className="text-xs gap-2 flex flex-col w-fit max-w-[90%] justify-start items-end text-left bg-[#e1e1e1] bg-opacity-30 py-2 pl-2 pr-4 rounded-l-md rounded-tr-md shadow-sm">
                                            {/* {dislike.answer} */}
                                            <p
                                                className="text-sm text-justify break-words"
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                }}
                                            >
                                                {dislike.answer &&
                                                    makeUrlsClickable(dislike.answer).map(
                                                        (part, index) => (
                                                            <React.Fragment key={index}>
                                                                {part}
                                                            </React.Fragment>
                                                        )
                                                    )}
                                            </p>
                                            {dislike.attachments &&
                                                dislike.attachments.length > 0 && (
                                                    <React.Fragment>
                                                        {dislike.attachments && (
                                                            <div className="flex flex-wrap">
                                                                {dislike.attachments.map(
                                                                    (attach) => {
                                                                        if (
                                                                            attach.type === 'image'
                                                                        ) {
                                                                            return (
                                                                                <img
                                                                                    key={attach.id} // Add a key prop for React
                                                                                    className="h-28 aspect-auto object-cover flex w-28 hover:object-contain m-1" // Added margin for spacing
                                                                                    src={
                                                                                        attach.filename
                                                                                    }
                                                                                    alt={
                                                                                        attach.filename
                                                                                    }
                                                                                />
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="h-28 w-full py-5 flex text-xs"
                                                                                    key={attach.id}
                                                                                >
                                                                                    <img
                                                                                        alt="PDF Icon"
                                                                                        className="h-full w-fit"
                                                                                        src={
                                                                                            PDFIcon
                                                                                        }
                                                                                    />
                                                                                    <div className="w-full">
                                                                                        <p className="font-bold text-darkgrey">
                                                                                            Filename:
                                                                                        </p>
                                                                                        <Link
                                                                                            className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                            to={
                                                                                                attach.filename
                                                                                            }
                                                                                            target="_blank"
                                                                                        >
                                                                                            {
                                                                                                attach.filename
                                                                                            }
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    }
                                                                )}
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            <div className="w-full flex gap-4 justify-between">
                                                {dislike.type === 0 && (
                                                    <div className="flex gap-2">
                                                        <div className="flex">
                                                            <span className="text-main">
                                                                <BiSolidLike />
                                                            </span>
                                                            <span>{dislike.likes_count}</span>
                                                        </div>
                                                        <div className="flex">
                                                            <span className="text-grey">
                                                                <BiSolidDislike />
                                                            </span>
                                                            <span>{dislike.dislikes_count}</span>
                                                        </div>
                                                    </div>
                                                )}
                                                <div>
                                                    <span>{dislike.created_at}</span>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
