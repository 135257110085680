import React, { useState } from 'react'
import ImageView from 'components/ImageView'
import { FaFileDownload } from 'react-icons/fa'
import { LoadingComponent } from 'components/LoadingComponent'
import { NoData } from 'components/NoData'
import { useMutation } from 'react-query'
import { getExportResponses } from 'services/request/Exports'
import ResponseMessageChat from 'components/Responses/FilteredChatroom'

function CompaniesChat({ selectedQuestion, selectedEvent, questionResponses, isLoading }) {
    const [selectedResponse, setSelectedResponse] = useState(null)
    const [selectedImage, setSelectedImage] = useState()

    const { mutate: ExportData } = useMutation(['GetExportApi'], (data) => {
        getExportResponses(data)
    })

    const exportAllData = (event, question) => {
        const data = {
            event_id: event.event_id,
            question_id: question.id,
            type: question.type,
            event: event,
            question: question,
        }
        ExportData(data)
    }

    const [showResponse, setShowResponse] = useState(false)
    const [showReply, setShowReply] = useState({})

    const toggleResponse = () => setShowResponse(!showResponse)
    const toggleReply = (replyIndex) =>
        setShowReply((prev) => ({
            ...prev,
            [replyIndex]: !prev[replyIndex],
        }))

    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }
    return (
        <div className=" w-2/4 bg-white min-h-full h-[100%] rounded-md custom-scroll overflow-y-auto border-l border-opacity-50 border-gray-400 grow">
            {selectedImage != null && (
                <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
            )}

            <div className="bg-white sm:w-[100%] max-w-[680px] min-h-full  h-fit ">
                <div className="w-full flex flex-col gap-2 px-5 py-3">
                    <div className="w-full h-fit border-b border-main border-opacity-40 pb-5">
                        <div className="w-full text-xl flex flex-col text-main font-bold border-l-4 border-main pl-4">
                            <div className="w-full ">
                                <p className="w-full text-left text-2xl font-bold">
                                    {selectedQuestion[0] === 0
                                        ? 'Select an Event Question'
                                        : selectedEvent?.event_name}
                                </p>

                                <div className="text-lg">
                                    <p>Responses to Question: </p>
                                    <span>{selectedQuestion.question}</span>
                                </div>
                            </div>
                            <div className="w-full text-sm flex gap-2 items-end text-main font-bold cursor-pointer  mt-2">
                                <span
                                    onClick={() => exportAllData(selectedEvent, selectedQuestion)}
                                    className="flex justify-center items-center hover:text-xl"
                                >
                                    <FaFileDownload />
                                    <p className="text-sm">Download</p>
                                </span>
                                {/* <span
                                    onClick={() => setIsDownload(true)}
                                    className="flex justify-center items-center hover:text-xl"
                                >
                                    <FaFileDownload />
                                    <p className="text-sm">Download PDF</p>
                                </span> */}
                            </div>
                        </div>
                    </div>
                    {isLoading ? (
                        <span className="">
                            <LoadingComponent title={'Responses'} />
                        </span>
                    ) : questionResponses.length == 0 ? (
                        <NoData />
                    ) : (
                        <div className="flex flex-col w-full ml-5 px-6 animate_fade_up">
                            {questionResponses.map((response, responseIndex) => (
                                <ResponseMessageChat
                                    key={responseIndex}
                                    response={response}
                                    showReply={showReply}
                                    toggleReply={toggleReply}
                                    showResponse={showResponse}
                                    toggleResponse={toggleResponse}
                                    setSelectedImage={setSelectedImage}
                                    makeUrlsClickable={makeUrlsClickable}
                                    selectedResponse={selectedResponse}
                                    setSelectedResponse={setSelectedResponse}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CompaniesChat
