import { DownloadProgress } from 'components/DownloadingComponent'
import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'

const PdfPreview = ({loading, pdfPreview, dataEvent, handleClose}) => {
  return (
    <div className="w-[45%] sm:w-full md:w-[50%] fixed right-0 top-8 bottom-0 z-30 h-screen custom-scroll bg-white animate_move_to_Left shadow-md">
    <div className="w-full flex flex-col items-center justify-between px-5 sticky -top-4 bg-white pt-4 shadow-md">
        {loading ? (
            <DownloadProgress progress={loading} />
        ) : (
            <div className="w-full flex items-center justify-between gap-4">
                <div className="flex gap-2 mt-2">
                    <button
                        onClick={() => {
                            const link = document.createElement('a')
                            link.href = pdfPreview
                            link.download = `Export Event of ${dataEvent.event_name} Responses File.pdf`
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                        }}
                        className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                    >
                        Download PDF
                    </button>
                </div>

                <p
                    className="text-2xl opacity-70 hover:opacity-100 items-center text-main cursor-pointer"
                    onClick={handleClose}
                >
                    <AiOutlineCloseCircle />
                </p>
            </div>
        )}
        <p className="text-xs text-main pb-2 opacity-70 font-semibold">Preview</p>
    </div>
    <iframe
        src={`${pdfPreview}#toolbar=0`}
        width="105%"
        height="90%"
        title="PDF Preview"
    ></iframe>
</div>  )
}

export default PdfPreview