import React, { useEffect, useState } from 'react'
import { MdOutlineFileDownload, MdOutlineSubdirectoryArrowLeft } from 'react-icons/md'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { Divider } from '@tremor/react'
import ExportNumberofEntries from 'components/Exports/ExportNumberofEntries'
import {
    getEventDocxFile,
    getEventPdfFile,
    getExportNumEntriesGDResponses,
    getExportNumEntriesResponses,
} from 'services/request/Exports'
import { getUserGenDiscussionApi } from 'services/request/NumberOfEntries'
import { NoData } from 'components/NoData'
import { useMutation } from 'react-query'
import { LoadingComponent } from 'components/LoadingComponent'
import { DownloadProgress } from 'components/DownloadingComponent'
import { ChatMessage } from 'components/Responses/FilteredResponse'
import ResponseMessage from 'components/Responses/FilteredReplies'
import PdfPreview from 'components/Preview/PdfPreview'

const ChatConvo = ({ userConvos, onClose, selectedEvent, isLoading, agent, selectedType }) => {
    const [selectedResponse, setSelectedResponse] = useState(null)
    const [selectedImage, setSelectedImage] = useState()
    const [generalDiscussion, setGeneralDiscussion] = useState()
    const [openGenDiscussion, setOpenGenDiscussion] = useState(false)
    const [questionState, setQuestionState] = useState({})
    const [toggleFilter, setToggleFilter] = useState(false)
    const [toggleFilterDocx, setToggleFilterDocx] = useState(false)
    const [toggleFilterPDF, setToggleFilterPDF] = useState(false)
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState()

    const { mutate: GenDisFetch, isLoxading: GDLoading } = useMutation(
        (data) => getUserGenDiscussionApi(data),
        {
            onSuccess: (res) => {
                setGeneralDiscussion(res.data)
            },
        }
    )

    const handleGenDis = (selectedEvent, agent, type, is_gd, is_csv) => {
        setOpenGenDiscussion(!openGenDiscussion)
        GenDisFetch({ selectedEvent, agent, type, is_gd, is_csv })
    }

    const [answersContainer, setAnswerContainer] = useState(() => {
        if (!userConvos) {
            return []
        }

        return (
            userConvos &&
            userConvos.map((response) => {
                return (
                    response.kiq.questions &&
                    response.kiq.questions.map((question) => {
                        const responseData = `Question: \n ${question.question} \nResponse : 
    ${
        question.responses &&
        question.responses.map((response) => {
            const attachmentStrings =
                response.attachments &&
                response.attachments.length > 0 &&
                response.attachments.map((attach) => attach.filename)
            const repliesStrings =
                response.responses &&
                response.responses.map(
                    (reply) => reply.answer + ' replied by: ' + reply.user.fullname
                )

            return ` ${response.answer + '\n by: ' + response.user.fullname} \n ${
                attachmentStrings ? `Attachments: \n ${attachmentStrings.join('\n ')}` : ''
            }
                \n ${repliesStrings ? `Replies: \n ${repliesStrings.join('\n ')}` : ''}
                `
        })
    }`
                        return `${responseData}\n`
                    })
                )
            })
        )
    })

    useEffect(() => {
        setAnswerContainer(
            userConvos &&
                userConvos.map((response) => {
                    return (
                        response.kiq.questions &&
                        response.kiq.questions.map((question) => {
                            const responseData = `Question: \n ${question.question} \nResponse : 
    ${
        question.responses &&
        question.responses.map((response) => {
            const attachmentStrings =
                response.attachments &&
                response.attachments.length > 0 &&
                response.attachments.map((attach) => attach.filename)
            const repliesStrings =
                response.responses &&
                response.responses.map(
                    (reply) => reply.answer + ' replied by: ' + reply.user.fullname
                )

            return ` ${response.answer + '\n by: ' + response.user.fullname} \n ${
                attachmentStrings ? `Attachments: \n ${attachmentStrings.join('\n ')}` : ''
            }
            \n ${repliesStrings ? `Replies: \n ${repliesStrings.join('\n ')}` : ''}
            `
        })
    }`
                            return `${responseData}\n`
                        })
                    )
                })
        )
    }, [userConvos])

    const toggleResponses = (questionId) => {
        setQuestionState((prevStates) => ({
            ...prevStates,
            [questionId]: !prevStates[questionId],
        }))
    }

    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline break-all font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }

    const exportCSVGDRecord = (data, agent) => {
        getExportNumEntriesGDResponses(data, agent, selectedEvent)
    }

    const exportCSVRecord = (data, agent) => {
        getExportNumEntriesResponses(data, agent, selectedEvent)
    }

    const handleDownloadDocx = async (eventId, type, order, userId) => {
        try {
            setLoading(true)

            await getEventDocxFile({
                event: eventId,
                intel_type: type,
                order_type: order,
                user_id: userId,
                event_name: selectedEvent.event_name,
                onDownloadProgress: (data) => {
                    setProgress(data)
                },
            })

            setLoading(false)
        } catch (e) {
            console.error('Error while downloading DOCX:', e)
            setLoading(false)
        }
    }

    const [pdfPreview, setPdfPreview] = useState(null)
    const handleDownloadPDF = async (eventId, type, order, userId) => {
        try {
            setLoading(true)

            await getEventPdfFile(
                {
                    event: eventId,
                    intel_type: type,
                    order_type: order,
                    user_id: userId,
                    event_name: selectedEvent.event_name,
                },
                null,
                setPdfPreview
            )

            setLoading(false)
        } catch (e) {
            console.error('Error while downloading PDF:', e)
            setLoading(false)
        }
    }
    const handleClose = () => {
        setPdfPreview(null)
    }

    const [isDownload, setIsDownload] = useState(false)
    const [showChat, setShowChat] = useState(false)
    const toggleChat = (chatId) => {
        setShowChat((prev) => ({
            ...prev,
            [chatId]: !prev[chatId],
        }))
    }
    const [showReply, setShowReply] = useState({})

    const toggleReply = (replyIndex) =>
        setShowReply((prev) => ({
            ...prev,
            [replyIndex]: !prev[replyIndex],
        }))

    return (
        <div className="rounded-lg w-full flex justify-center items-start sm:h-fit md:h-fit">
            {pdfPreview && (
                <PdfPreview
                    handleClose={handleClose}
                    dataEvent={selectedEvent}
                    pdfPreview={pdfPreview}
                    loading={loading}
                />
            )}
            {isDownload && (
                <div className="absolute z-50 w-full animate_move_to_Left">
                    <ExportNumberofEntries
                        eventSelected={selectedEvent}
                        generalDiscussion={generalDiscussion}
                        data={userConvos}
                        onClose={() => setIsDownload(false)}
                    />
                </div>
            )}
            <div className="w-full py-4 px-4 flex flex-col">
                <div className=" w-full flex justify-center">
                    {!isLoading && (
                        <div className="w-full h-full p-2 text-lg sticky top-0 z-10 flex justify-between items-center text-main font-bold bg-white bg-opacity-80 backdrop-blur-sm">
                            <div
                                className="flex w-1/3 hover:text-xl cursor-pointer"
                                onClick={onClose}
                            >
                                <IoIosCloseCircleOutline />
                            </div>
                        </div>
                    )}
                    <div className="w-2/3 gap-2 h-full mt-2 justify-end flex text-main">
                        {loading ? (
                            <DownloadProgress progress={progress} />
                        ) : selectedType === 0 ? (
                            <button
                                onClick={() => setToggleFilterDocx(!toggleFilterDocx)}
                                className="w-fit relative whitespace-nowrap hover:text-xl flex items-center h-fit cursor-pointer"
                            >
                                <p className="text-sm">Docx</p>
                                <MdOutlineFileDownload />
                                {toggleFilterDocx && (
                                    <div className="animate_fade_up z-50 mt-[5rem] -ml-20 absolute transition-all duration-300 cursor-pointer hover:bg-opacity-100 flex flex-col text-left rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main">
                                        <button
                                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                            onClick={() =>
                                                handleDownloadDocx(
                                                    selectedEvent?.id,
                                                    ['General Discussions'],
                                                    'desc',
                                                    agent?.id
                                                )
                                            }
                                        >
                                            General Discussion Docx
                                            <span className="text-lg">
                                                <MdOutlineFileDownload />
                                            </span>
                                        </button>
                                        <button
                                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                            onClick={() =>
                                                userConvos.length > 0 &&
                                                handleDownloadDocx(
                                                    selectedEvent?.id,
                                                    ['Chatroom KIQs'],
                                                    'desc',
                                                    agent?.id
                                                )
                                            }
                                            disabled={userConvos.length === 0}
                                        >
                                            Chatroom KIQs Docx
                                            <span className="text-lg">
                                                <MdOutlineFileDownload />
                                            </span>
                                        </button>
                                    </div>
                                )}
                            </button>
                        ) : selectedType === 1 ? (
                            <button
                                className="text-xs w-full flex items-center gap-1 hover:scale-105 transition-all duration-300 cursor-pointer"
                                onClick={() =>
                                    handleDownloadDocx(
                                        selectedEvent?.id,
                                        ['My KIQs'],
                                        'desc',
                                        agent?.id
                                    )
                                }
                            >
                                DOCX
                                <span className="text-lg">
                                    <MdOutlineFileDownload />
                                </span>
                            </button>
                        ) : selectedType === 2 ? (
                            <button
                                className="text-xs w-full flex items-center gap-1 hover:scale-105 transition-all duration-300 cursor-pointer"
                                onClick={() =>
                                    handleDownloadDocx(
                                        selectedEvent?.id,
                                        ['SnapIntel'],
                                        'desc',
                                        agent?.id
                                    )
                                }
                            >
                                DOCX
                                <span className="text-lg">
                                    <MdOutlineFileDownload />
                                </span>
                            </button>
                        ) : null}

                        {loading ? (
                            <DownloadProgress progress={progress} />
                        ) : selectedType === 0 ? (
                            <button
                                onClick={() => setToggleFilterPDF(!toggleFilterPDF)}
                                className="w-fit relative whitespace-nowrap hover:text-xl flex items-center h-fit cursor-pointer"
                            >
                                <p className="text-sm">PDF</p>
                                <MdOutlineFileDownload />
                                {toggleFilterPDF && (
                                    <div className="animate_fade_up z-50 mt-[5rem] -ml-20 absolute transition-all duration-300 cursor-pointer hover:bg-opacity-100 flex flex-col text-left rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main">
                                        <button
                                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                            onClick={() =>
                                                handleDownloadPDF(
                                                    selectedEvent?.id,
                                                    ['General Discussions'],
                                                    'desc',
                                                    agent?.id
                                                )
                                            }
                                        >
                                            General Discussion PDF
                                            <span className="text-lg">
                                                <MdOutlineFileDownload />
                                            </span>
                                        </button>
                                        <button
                                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                            onClick={() =>
                                                handleDownloadPDF(
                                                    selectedEvent?.id,
                                                    ['Chatroom KIQs'],
                                                    'desc',
                                                    agent?.id
                                                )
                                            }
                                            // disabled={userConvos.length === 0}
                                        >
                                            Chatroom KIQs PDF
                                            <span className="text-lg">
                                                <MdOutlineFileDownload />
                                            </span>
                                        </button>
                                    </div>
                                )}
                            </button>
                        ) : selectedType === 1 ? (
                            <button
                                className="text-xs w-full flex items-center gap-1 hover:scale-105 transition-all duration-300 cursor-pointer"
                                onClick={() =>
                                    handleDownloadPDF(
                                        selectedEvent?.id,
                                        ['My KIQs'],
                                        'desc',
                                        agent?.id
                                    )
                                }
                            >
                                PDF
                                <span className="text-lg">
                                    <MdOutlineFileDownload />
                                </span>
                            </button>
                        ) : selectedType === 2 ? (
                            <button
                                className="text-xs w-full flex items-center gap-1 hover:scale-105 transition-all duration-300 cursor-pointer"
                                onClick={() =>
                                    handleDownloadPDF(
                                        selectedEvent?.id,
                                        ['SnapIntel'],
                                        'desc',
                                        agent?.id
                                    )
                                }
                            >
                                PDF
                                <span className="text-lg">
                                    <MdOutlineFileDownload />
                                </span>
                            </button>
                        ) : null}

                        {selectedType === 0 ? (
                            <button
                                onClick={() => setToggleFilter(!toggleFilter)}
                                className="w-fit relative whitespace-nowrap hover:text-xl flex items-center h-fit cursor-pointer"
                            >
                                <p className="text-sm"> CSV</p>
                                <MdOutlineFileDownload />
                                {toggleFilter && (
                                    <div className="animate_fade_up z-50 mt-[5rem] -ml-32 absolute transition-all duration-300 cursor-pointer hover:bg-opacity-100 flex flex-col text-left rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main">
                                        <button
                                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                            onClick={() => exportCSVGDRecord(userConvos, agent)}
                                        >
                                            General Discussion CSV
                                            <span className="text-lg">
                                                <MdOutlineFileDownload />
                                            </span>
                                        </button>
                                        <button
                                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                            onClick={() =>
                                                userConvos.length > 0 &&
                                                exportCSVRecord(userConvos, agent)
                                            }
                                            disabled={userConvos.length === 0}
                                        >
                                            Chatroom CSV
                                            <span className="text-lg">
                                                <MdOutlineFileDownload />
                                            </span>
                                        </button>
                                    </div>
                                )}
                            </button>
                        ) : (
                            <div
                                onClick={() => exportCSVRecord(userConvos, agent)}
                                className="flex w-fit justify-end hover:text-xl cursor-pointer"
                            >
                                <p className="text-sm"> CSV</p>
                                <MdOutlineFileDownload />
                            </div>
                        )}
                    </div>
                </div>

                <div className="px-4 w-full text-xs">
                    {selectedType == 0 && (
                        <button
                            onClick={() => handleGenDis(selectedEvent, agent, 0, 1, 0)}
                            className="text-md w-full text-center bg-main rounded-md my-2 shadow-sm py-2 text-white font-semibold hover:bg-opacity-80"
                        >
                            General Discussion
                        </button>
                    )}

                    {openGenDiscussion ? (
                        GDLoading ? (
                            <LoadingComponent title="General Discussion" />
                        ) : generalDiscussion?.responses?.length > 0 ? (
                            generalDiscussion?.responses.map((response, responseIndex) => (
                                <div
                                    className="cursor-pointer text-sm  border-main px-2 pt-2"
                                    key={responseIndex}
                                >
                                    <span className="text-sm font-bold   ">
                                        {response?.user?.fullname}
                                    </span>

                                    <div className="text-xs gap-2 flex flex-col w-fit max-w-[90%] justify-start items-start text-left bg-gray-300  bg-opacity-30 py-2 pl-2 pr-4 rounded-bl-md rounded-r-md shadow-sm">
                                        <ChatMessage
                                            key={response.id}
                                            chat={response}
                                            showChat={showChat}
                                            toggleChat={toggleChat}
                                            setSelectedImage={setSelectedImage}
                                            makeUrlsClickable={makeUrlsClickable}
                                        />
                                    </div>

                                    <div className="w-full flex flex-col gap-2">
                                        <div className="px-10 flex gap-2">
                                            {response.responses?.length > 0 && (
                                                <div className="flex">
                                                    <span className="rotate-90 text-base justify-center items-center ">
                                                        <MdOutlineSubdirectoryArrowLeft />
                                                    </span>
                                                    <span
                                                        onClick={() =>
                                                            setSelectedResponse(
                                                                selectedResponse === response
                                                                    ? null
                                                                    : response
                                                            )
                                                        }
                                                        className="cursor-pointer "
                                                    >
                                                        {selectedResponse === response
                                                            ? 'Hide'
                                                            : 'Show'}{' '}
                                                        Replies
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        {selectedResponse === response &&
                                            response.responses?.length > 0 && (
                                                <React.Fragment>
                                                    <div className="flex flex-col w-full ml-5 px-6 animate_fade_up">
                                                        {response.responses.map((response) => (
                                                            <ResponseMessage
                                                                key={response.id}
                                                                response={response}
                                                                showReply={showReply}
                                                                toggleReply={toggleReply}
                                                                setSelectedImage={setSelectedImage}
                                                                makeUrlsClickable={
                                                                    makeUrlsClickable
                                                                }
                                                            />
                                                        ))}
                                                    </div>
                                                </React.Fragment>
                                            )}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <NoData />
                        )
                    ) : null}

                    {openGenDiscussion && generalDiscussion?.responses.length > 0 && (
                        <Divider className="font-bold text-md text-main text-center">
                            End of General Discussions
                        </Divider>
                    )}

                    {userConvos &&
                        userConvos.map((userConvo, index) => {
                            const isType2 = userConvo.type === 2
                            return userConvo.kiq?.questions
                                .slice(isType2 ? 0 : undefined, isType2 ? 7 : undefined)
                                .map((question, index) => {
                                    return (
                                        <div className="flex flex-col gap-2 text-xs" key={index}>
                                            <div className="text-left text-[.8em] self-start bg-main text-base py-2 px-4  rounded-t-lg rounded-br-lg">
                                                {question.question}
                                            </div>

                                            <span
                                                className="transition-all duration-300 mb-2 cursor-pointer hover:font-bold"
                                                onClick={() => toggleResponses(question.id)}
                                            >
                                                {questionState[question.id]
                                                    ? `Hide (${question?.responses?.length}) response`
                                                    : `Show (${question?.responses?.length}) response`}
                                            </span>

                                            {questionState[question.id] && (
                                                <div>
                                                    {question &&
                                                        question.responses.map(
                                                            (response, responseIndex) => {
                                                                return (
                                                                    <div>
                                                                        <div key={responseIndex}>
                                                                            <span className=" font-bold text-black">
                                                                                {
                                                                                    response?.user
                                                                                        ?.fullname
                                                                                }
                                                                            </span>
                                                                            <div className="text-xs gap-2 flex flex-col mb-3 w-fit max-w-[75%] justify-start items-start text-left bg-[#cbcbcb] bg-opacity-30 py-2 pl-2 pr-4 rounded-l-md rounded-tr-md shadow-sm">
                                                                                <ChatMessage
                                                                                    key={
                                                                                        response.id
                                                                                    }
                                                                                    chat={response}
                                                                                    showChat={
                                                                                        showChat
                                                                                    }
                                                                                    toggleChat={
                                                                                        toggleChat
                                                                                    }
                                                                                    setSelectedImage={
                                                                                        setSelectedImage
                                                                                    }
                                                                                    makeUrlsClickable={
                                                                                        makeUrlsClickable
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="px-10 flex gap-2">
                                                                            {response.responses
                                                                                ?.length > 0 && (
                                                                                <div className="flex mb-3 gap-2">
                                                                                    <span className="rotate-90 text-base justify-center items-center ">
                                                                                        <MdOutlineSubdirectoryArrowLeft />
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            setSelectedResponse(
                                                                                                selectedResponse ===
                                                                                                    response
                                                                                                    ? null
                                                                                                    : response
                                                                                            )
                                                                                        }
                                                                                        className="cursor-pointer "
                                                                                    >
                                                                                        {selectedResponse ===
                                                                                        response
                                                                                            ? ' Hide'
                                                                                            : ' Show'}{' '}
                                                                                        Replies
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        {selectedResponse ===
                                                                            response &&
                                                                            response.responses
                                                                                ?.length > 0 && (
                                                                                <React.Fragment>
                                                                                    <div className="flex flex-col w-full ml-5 px-6 animate_fade_up">
                                                                                        {response.responses.map(
                                                                                            (
                                                                                                response
                                                                                            ) => (
                                                                                                <ResponseMessage
                                                                                                    key={
                                                                                                        response.id
                                                                                                    }
                                                                                                    response={
                                                                                                        response
                                                                                                    }
                                                                                                    showReply={
                                                                                                        showReply
                                                                                                    }
                                                                                                    toggleReply={
                                                                                                        toggleReply
                                                                                                    }
                                                                                                    setSelectedImage={
                                                                                                        setSelectedImage
                                                                                                    }
                                                                                                    makeUrlsClickable={
                                                                                                        makeUrlsClickable
                                                                                                    }
                                                                                                />
                                                                                            )
                                                                                        )}
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )}
                                                                    </div>
                                                                )
                                                            }
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                    )
                                })
                        })}
                </div>
            </div>
        </div>
    )
}

export default ChatConvo
